import React, { useState, useEffect } from "react";
import Datatable from "../../Components/Datatable"
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../../context/AuthContext';
import Swal from 'sweetalert2'
import GeneralRequestHelper from '../../../../../Helpers/GeneralRequestHelper';
export const Bank = () => {
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation()
    const [data, setData] = useState([]);
    const [SortIdValue, setSortIdValue] = useState([]);

    const calculateSortId = async () => {
        try {
            await GeneralRequestHelper("/api/admin/Gateway/GetList", "get", "", user.token).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        if (res.data['data'].length !== 0) {
                            setSortIdValue(Math.max.apply(Math, res.data['data'].map(function (o) { return o.sortId; })) + 1)
                        }
                        else {
                            setSortIdValue(1)
                        }
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    const handleSubmit = async (e) => {
        calculateSortId();
        e.preventDefault();
        try {
            const dataa = {
                sortId: SortIdValue,
                gatewayName: document.getElementById("gatewayName").value,
                typeName: document.getElementById("paymentmethod").value,
                typeDescription: document.getElementById("ibanNreceiver").value,
                isActive: true
            }
            await GeneralRequestHelper("/api/admin/Gateway/Create", "post", dataa, user.token).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            window.location.reload();
                        })
                    }
                    else {
                        setMsg(res.data['message']);
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            timer: 3000,
                            confirmButtonText: `Tamam`
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }

    const columns = React.useMemo(
        () => [
            {
                Header: t("paymentmethod"),
                Footer: t("paymentmethod"),
                accessor: 'typeName',
            }
            ,
            {
                Header: t("bankName"),
                Footer: t("bankName"),
                accessor: 'gatewayName',
            },

            {
                Header: t('ibanNreceiver'),
                Footer: t('ibanNreceiver'),
                accessor: 'typeDescription',
            }
            ,
            {
                Header: t('sortid'),
                Footer: t('sortid'),
                accessor: 'sortId',
            }
            ,
            {
                Header: t('isActive'),
                Footer: t('isActive'),
                accessor: 'isActive',
            }
            ,
            {
                Header: t("editBank"),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-warning mr-2  btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t("edit")}
                        </button>

                    </div>
                ),
                id: 'actionEdit',
                Footer: t("editBank"),
            },
        ],
        [],
    )
    const handleEdit = (row) => {
        window.location.href = "/dashboard/admin/bankedit/" + row['id'];
    }


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await GeneralRequestHelper("/api/admin/Gateway/GetList", "get", "", user.token).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                let a = "";
                                if (res.data['data'][i]['isActive']) {
                                    a = t('active');
                                }
                                else {
                                    a = t('passive');
                                }
                                data.push({
                                    "id": res.data['data'][i]['id'],
                                    "gatewayName": res.data['data'][i]['gatewayName'],
                                    "typeName": res.data['data'][i]['typeName'],
                                    "typeDescription": res.data['data'][i]['typeDescription'],
                                    "sortId": res.data['data'][i]['sortId'],
                                    "isActive": a
                                })
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            } catch (err) {
                setMsg(err.message);
            }
            setLoading(false);
        }
        fetchData();

    }, []);


    return (
        <div style={{ backgroundColor: "white", padding: "3%" }}>
            <div style={{ textAlign: "center" }}>
                <h1>{t("createpaymentmethod")}</h1>
            </div>
            <br />
            <h3>{msg}</h3>
            <form onSubmit={handleSubmit} style={{ border: "1px solid black", padding: "2%" }}>
                <div class="mb-3">
                    <label for="paymentmethod" class="form-label">{t("paymentmethod")}</label>
                    <textarea class="form-control" name="paymentmethod" id="paymentmethod" required />
                </div>
                <div class="mb-3">
                    <label for="gatewayName" class="form-label">{t("bankName")}</label>
                    <textarea class="form-control" id="gatewayName" required />
                </div>
                <div class="mb-3">
                    <label for="ibanNreceiver" class="form-label">{t("ibanNreceiver")}</label>
                    <textarea class="form-control" id="ibanNreceiver" required />
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                </div>

            </form>
            <br />
            <hr />
            <div style={{ textAlign: "center" }}>
                <h1>{t("paymentmethodlist")}</h1>

                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <div>
                        <Datatable data={data} columns={columns} tableBaslik={t("paymentmethodlist")} />
                    </div>
                )}


            </div>
        </div>
    )
}
export default Bank;
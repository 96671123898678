import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const VideoContent = (props) => {
    const { t, i18n } = useTranslation()
    const params = useParams();
    const navigate = useNavigate();
    const [url, setUrl] = useState([]);

    function nextquestion() {
        var n = Number(params['id']);
        var newId = (n + 1).toString();
        if (Number(newId) > props.questionCount) {
            navigate("/dashboard/user/course/subject");
        }
        else {
            window.location.href = "/dashboard/user/course/question/" + params['subjectid'] + "/" + newId;
        }
    }


    useEffect(() => {
        setUrl(props.data['contentStr'])
    });

    return (
        <div>
            <div className='player-wrapper' style={{ position: "relative" }} >
                <ReactPlayer
                    className='react-player'
                    url={url}
                    width='100%'
                    height="auto"
                    controls="true"
                />


            </div>
            <br />
            <div className='d-flex justify-content-center'>
                <button type="button" class="btn btn-primary btn-lg" onClick={nextquestion}>{t("continue")}</button>
            </div>
            <br /><hr />
        </div>
    );
};

export default VideoContent;
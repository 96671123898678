import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
const InfoInput = (props) => {
    const { t, i18n } = useTranslation()
    return (
        <div class="mb-3">
            <label for="infoBoxText" class="form-label">{t("infoBoxText")}</label> <br /><i>boş ise 1 ader boşluk giriniz.</i>
            <textarea class="form-control" id="infoBoxText" required />
        </div>
    );
};

export default InfoInput;
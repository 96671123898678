import React, { useState, useEffect } from "react";
import Datatable from "../../../Components/Datatable"
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../../context/AuthContext';
import Swal from 'sweetalert2'
import axios from "axios";

export const AdminSectionList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);

    const columns = React.useMemo(
        () => [
            {
                Header: t('sectionName'),
                Footer: t('sectionName'),
                accessor: 'sectionName',
            },
            {
                Header: t('sectionDescription'),
                Footer: t('sectionDescription'),
                accessor: 'sectionDescription',
            },
            {
                Header: t('sortid'),
                Footer: t('sortid'),
                accessor: 'sortId',
            },
            {
                Header: t('deleteSection'),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">

                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleDelete(originalRow)}
                        >
                            {t('delete')}
                        </button>
                    </div>
                ),
                id: 'action',
                Footer: t('deleteSection'),
            },
            {
                Header: t('editSection'),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-info btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t('edit')}
                        </button>

                    </div>
                ),
                id: 'actionEditCourse',
                Footer: t('editSection'),
            },

        ],
        [],
    )

    const handleEdit = (row) => {
        window.location.href = "/dashboard/admin/editsection/" + row['id'];

    }


    const handleDelete = async (row) => {
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/SectionManager/Update";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    id: row['id'],
                    isDeleted: true,
                    sectionName: row['sectionName'],
                    sectionDescription: row['sectionDescription'],
                    sortId: row['sortId'],
                    isActive: true
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            // text: "You clicked the button!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }


    useEffect(() => {
        const fetchdata = async () => {
            setLoading(true);

            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/SectionManager/GetList";

                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {

                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                let activity = "";
                                if (res.data['data'][i]['isActive']) {
                                    activity = t('active');
                                }
                                else {
                                    activity = t('passive');
                                }
                                data.push({
                                    "id": res.data['data'][i]['id'],
                                    "sectionName": res.data['data'][i]['sectionName'],
                                    "sectionDescription": res.data['data'][i]['sectionDescription'],
                                    "sortId": res.data['data'][i]['sortId'],
                                    "isActive": activity,
                                    "isDeleted": res.data['data'][i]['isDeleted'],
                                })
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdata();
    }, [])


    return (
        <div style={{ backgroundColor: "white", width: "full" }} >
            <div style={{ textAlign: "center" }}>
                <h3>{msg}</h3>
                <h1>{t("sectionList")}</h1>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <div>
                        <Datatable data={data} columns={columns} tableBaslik={t("sectionList")} />
                    </div>
                )}
            </div>



        </div>
    );
};
export default AdminSectionList;

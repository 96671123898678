import React from 'react';
import { useTranslation } from "react-i18next";
const TitleArea = (props) => {
    const { t, i18n } = useTranslation()
    return (
        <div>
            <br />
            <h2>{props.text}</h2>
            <br />
            <h4>{props.msg}</h4>
            <hr />
            <br />
        </div>
    );
};

export default TitleArea;
import React from 'react';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../context/AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SidebarHeader = (props) => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    return (
        <div class="sidebar-header" style={{ backgroundColor: "SteelBlue" }}>
            <br />
            <h4 onClick={props.onClick} className="forsidebar" id="arrow" style={{ backgroundColor: "SteelBlue", textAlign: "right" }} >
                <FontAwesomeIcon icon="fas fa-arrow-alt-circle-left" />
            </h4>
            <strong id="arroww" onClick={props.onClick}>
                <FontAwesomeIcon icon="fas fa-arrow-alt-circle-right" />
            </strong>
        </div>
    );
};

export default SidebarHeader;
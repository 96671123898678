import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";

function TeacherStudentCreate() {
    const { user } = useAuth();
    const { t, i18n } = useTranslation()
    const [msg, setMsg] = useState([]);
    const [classes, setClasses] = useState([]);
    const [loading, setLoading] = useState(true);



    const onSubmit = async (e) => {
        let classId = document.getElementById("classId").value;
        let a = classes[classId];
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.tokens
            }
            let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/CreateStudent";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    username: document.getElementById("username").value,
                    password: document.getElementById("password").value,
                    fullName: document.getElementById("fullName").value,
                    roleId: 2,
                    schoolId: a.schoolId,
                    classId: classId,
                    sectionId: a.sectionId,
                    isDeleted: false,
                },
                headers: headerss
            }).then(res => {
                console.log(res);
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                    else {
                        setMsg(res.data['message']);

                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            confirmButtonText: `Tamam`,
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            alert(err.message);
        }
    }


    useEffect(() => {
        const fetchdata = async () => {
            setLoading(true);

            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/manager/EditSchool/GetClasses";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                classes.push({
                                    "id": res.data['data'][i]['id'],
                                    "className": res.data['data'][i]['className'],
                                    "description": res.data['data'][i]['description'],
                                    "sectionId": res.data['data'][i]['sectionId'],
                                    "schoolId": res.data['data'][i]['schoolId'],
                                })
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }


            setLoading(false);
        }
        fetchdata();
    }, [])

    return (
        <div>

            <br />
            <h6>{msg}</h6>
            <div className="card">
                <div className="card-header d-flex justify-content-center">
                    <h2>{t("createstudent")}</h2>
                </div>
                <div className="card-body">
                    <form onSubmit={onSubmit}>
                        <div class="mb-3">
                            <label for="username" class="form-label"> {t('userEmail')} </label>
                            <input type="email" class="form-control" id="username" name="username" required />
                        </div>

                        <div class="mb-3">
                            <label for="fullName" class="form-label">{t('namesurname')}</label>
                            <input class="form-control" id="fullName" name="fullName" required />
                        </div>
                        <div class="mb-3">
                            <label for="password" class="form-label">{t('password')}</label>
                            <input type="password" class="form-control" name="password" id="password" required />
                        </div>
                        <div class="mb-3">
                            <label for="classId" class="form-label">{t('className')}</label>
                            <select id="classId" name="classId" class="form-select" required>
                                <option key="" value="">{t('pleaseSelectClass')}</option>
                                {classes ? (
                                    classes && classes.map((dt) => {
                                        return (
                                            <option key={dt.id} value={dt.id}>{dt.className}</option>
                                        )
                                    })
                                ) : (
                                    <p>Please Reflesh page</p>
                                )
                                }
                            </select>
                        </div>


                        <div className="d-flex justify-content-center">
                            <button type="submit" class="btn btn-primary">{t('create')}</button>
                        </div>
                    </form>
                </div>
                <div className="card-footer">

                </div>
            </div>
            <hr />
            <div>
                <h2>Toplu Oluşturma</h2>
                <h6>Excell yükleyerek toplu olarak Yükleyebilirsiniz.</h6>
                <h6><b>Kullanım:</b> Mevcut Excelinizi kopyalayıp yeni bir excel oluşturunuz. Öğrencinin Adı sütünu için "isim" isimli bir sütun. Email için "email" isimli bir sütun olmalıdır. Şifreler öğrenicin adı şeklinde otomatik olacaktır.</h6>

                <div className="card-body" style={{ border: "1px solid", padding: "10px" }}>
                    <form onSubmit={onSubmit}>

                        <div class="mb-3">
                            <label for="selectClass" class="form-label">Sınıf</label>
                            <select id="selectClass" class="form-select">
                                <option selected>12fenc</option>
                                <option>12fena</option>
                                <option>12fenb</option>
                                <option>12fend</option>
                            </select>
                        </div>


                        <div className="d-flex justify-content-center">
                            <button type="submit" class="btn btn-primary">{t("create")}</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default TeacherStudentCreate;

import React from 'react';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../context/AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MobileNavbarManager = (props) => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    return (
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
            <div class="container-fluid" style={{ backgroundColor: "white" }}>
                <a className="navbar-brand" href="/">
                    <img src="/images/logo.png" alt="logo" />
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">

                        <a href={"/dashboard/school/editschool/" + user.userDetail.schoolId} >
                            <b> {t('editSchool')}</b>
                        </a>
                        <hr />
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                <b> {t('teachers')}</b>
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/dashboard/school/teachercreate">                                    {t('createteacher')}
                                </a></li>
                                <li><a class="dropdown-item" href="/dashboard/school/teacherlist">  {t('teachersList')}</a></li>
                            </ul>
                        </li>
                        <hr />
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                <b> {t("classes")}</b>
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/dashboard/school/classlist">                                   {t("classlist")}
                                </a></li>
                                <li><a class="dropdown-item" href="/dashboard/school/createclass"> {t("createclass")}</a></li>
                            </ul>
                        </li>
                        <hr />
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                <b> {t("students")}</b>
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/dashboard/teacher/studentcreate">                                   {t("createstudent")}
                                </a></li>
                                <li><a class="dropdown-item" href="/dashboard/teacher/studentlist"> {t("studentList")}</a></li>
                            </ul>
                        </li>
                    </ul>
                    <hr />
                    <form class="d-flex" style={{ padding: "3%" }}>
                        <a href="/profile">
                            <i
                                class="fa fa-user-circle"
                                style={{ fontSize: 30 }}
                                aria-hidden="true"
                            >
                            </i>
                            &nbsp;
                            <span>

                                <strong>{user.name}</strong>
                                <br />
                                {user.role}
                            </span>
                        </a>
                    </form>
                </div>
            </div>
        </nav>
    );
};

export default MobileNavbarManager;
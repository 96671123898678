import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAuth } from '../../../../../../../context/AuthContext';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import Tag from '../UI/Tag';
import TitleArea from '../UI/TitleArea';

const VideoContent = (props) => {
    const { t } = useTranslation()
    const { user } = useAuth();
    const params = useParams()
    const navigate = useNavigate();
    const [msg, setMsg] = useState([]);
    const [videoPath, setVideoPath] = useState(null);
    const [data, setData] = useState([]);


    const handletype1 = async (e) => {
        e.preventDefault();
        try {
            var question = {
                isDeleted: data.isDeleted,
                tagId: document.getElementById("tagId").value,
                sortId: data.sortId,
                contentType: "video",
                subjectId: params["subject"],
                contentStr: videoPath,
                detail: "",
                notfyText: "",
                answer: "",
                id: params['sort']
            }

            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/ContentManager/Update";
            await axios({
                method: 'Post',
                url: url,
                data: question,
                headers: headerss
            }).then(res => {
                if (res.data['success']) {
                    Swal.fire({
                        title: "Başırılı!",
                        icon: "success",
                        confirmButtonText: `Tamam`,
                        timer: 3000,
                    }).then(() => {
                        window.location.href = "/dashboard/admin/questionlist";
                    });
                }
                else {
                    Swal.fire({
                        title: "Hata!",
                        text: "Soru Güncellenirken bir hata meydana geldi!",
                        icon: "error",
                        confirmButtonText: `Tamam`,
                    }).then(() => {
                        window.location.reload();
                    });
                }
            });
        }
        catch (err) {
            setMsg(err.message);
        }
    }
    const handleChange = async (event) => {
        if (event.target.type === "textarea") {
            setVideoPath(event.target.value)
        }
        else if (event.target.type === "file") {
            const formData = new FormData();
            formData.append('FormFile', event.target.files[0]);
            const url = "https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile";
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            try {
                await axios({
                    method: 'Post',
                    url: url,
                    params: {
                        mediaType: "video"
                    },
                    data: formData,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            setVideoPath("https://api.heryerdeingilizce.com/" + res.data['data']['path'])
                            alert("Yüklendi");
                        }
                        else {
                            alert("hata")
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            } catch (err) {
                setMsg(err.message);
            }
        }
    }

    const getQuestion = async () => {
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/ContentManager/GetById";
            await axios({
                method: 'GET',
                url: url,
                headers: headerss,
                params: {
                    id: params["sort"]
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        setData(res.data['data'])
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
    }
    useEffect(() => {
        getQuestion();
    }, [])

    return (
        <div>
            <TitleArea msg={msg} text={"Video Ders Oluşturma Sayfası"} />
            <Tag />
            <br />
            <form onSubmit={handletype1}>
                <div class="mb-3">
                    <label for="videocontent" class="form-label">{t("addVideo")}</label>
                    <input class="form-control" type="file" accept='video' onChange={handleChange} id="videocontent" name="videocontent" required />
                </div>
                <div class="mb-3">
                    <label for="addvideos" class="form-label">{t("addVideobyUrl")}</label>
                    <textarea class="form-control" rows={1} type="text" onChange={handleChange} id="addvideos" name="addvideos" />
                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                </div>
            </form>
        </div>
    );
};

export default VideoContent;
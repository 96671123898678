import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';

const Write = (props) => {
    const [obj, setObj] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [answerCount, setAnswerCount] = useState([]);
    const [url, setUrl] = useState([]);
    const [questionId, setQuestionId] = useState([]);


    const onClick = () => {
        window.location.href = "/dashboard/admin/editquestion/" + questionId;
    }

    useEffect(() => {
        setQuestionId(JSON.parse(props.data.id));

        setObj(JSON.parse(props.data.contentStr));
        let answerss = props.data.answer.split(' ')
        setAnswers(answerss);
        setAnswerCount(answerss.length);
        setUrl("http://api.heryerdeingilizce.com/" + JSON.parse(props.data.contentStr)['url']);
    });

    return (
        <div>
            <div style={{ alignItems: "center", textAlign: "center" }}>
                {obj['type'] === "sound" ? (
                    <ReactAudioPlayer
                        src={url}
                        autoPlay
                        controls
                    />
                ) : (
                    <div></div>
                )}
                {obj['type'] === "video" ? (
                    <iframe src={url} width="100%" height="580" frameborder="4" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                ) : (
                    <div></div>
                )}
                {obj['type'] === "image" ? (
                    <div style={{ width: "70%", marginLeft: "auto", marginRight: "auto " }}>
                        <img src={url} />
                    </div>
                ) : (
                    <div></div>
                )}
                <br />
                <h4>
                    {obj['text']}
                </h4>
            </div>
            <br />
            <div style={{ alignItems: "center", textAlign: "center" }}>
                <span>
                    {props.data.detail}
                </span>
            </div>
            <br />
            <div class="d-flex justify-content-center" style={{ fontSize: 20 }}>
                {answers && answers.map((dt) => {
                    return (
                        <div class="mb-3">
                            <input class="form-control" id="writeAnswer" required />
                        </div>
                    )
                })}
            </div>
            <div className='d-flex justify-content-center'>
                <button className='btn btn-info' onClick={onClick}>Soruyu Düzenle</button>
            </div>
        </div>
    );
};

export default Write;
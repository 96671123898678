import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import axios from "axios";
import 'quill/dist/quill.snow.css'
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

export const AdminCreateQuestion = () => {
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const params = useParams()
    const [sortId, setSortId] = useState([]);

    const navigate = useNavigate();

    const questionTypes =
        [
            {
                "id": 1,
                "name": "video",
                "description": "eğitim içeriği",
            },
            {
                "id": 2,
                "name": "choice",
                "description": "çoktan seçmeli",
            },
            {
                "id": 3,
                "name": "match",
                "description": "eşleştirme",
            },
            {
                "id": 4,
                "name": "write",
                "description": "yazma",
            },
            {
                "id": 5,
                "name": "trueFalse",
                "description": "doğru yanlış",
            },
            {
                "id": 6,
                "name": "realmatch",
                "description": "Toplu Eşleştirme",
            },
            {
                "id": 7,
                "name": "sentence",
                "description": "cümle kurma",
            }
        ];


    const onSubmit = async (e) => {
        try {
            let questiontype = await document.getElementById("contentType").value;
            let subject = params["subject"]
            navigate("/dashboard/admin/createquestiondetail/" + subject + "/" + questiontype + "/" + sortId);
        } catch (error) {
            alert(error.message);
        }
    }

    useEffect(() => {
        const getSortId = async () => {
            setLoading(true);

            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/ContentManager/GetListBySubject";

                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss,
                    params: {
                        subjectId: params['subject']
                    }
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            if (res.data['data'].length !== 0) {
                                setSortId(res.data['data'][res.data['data'].length - 1]['sortId'])
                            }
                            else {
                                setSortId(1)
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);

        }
        getSortId();

    }, [])


    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <h1>{t("createQuestion")}</h1>
            </div>
            <h4>{msg}</h4>
            {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
            {!loading && (
                <form onSubmit={onSubmit} >
                    <div class="mb-3">
                        <label for="contentType" class="form-label">{t("selectQuestionType")}</label>
                        <select id="contentType" class="form-select">
                            <option key="0" value={0}>{t('selectQuestionType')}</option>

                            {questionTypes ? (
                                questionTypes && questionTypes.map((dt) => {
                                    return (
                                        <option key={dt.id} value={dt.id}>{dt.name} - {dt.description}</option>
                                    );
                                })
                            ) : (
                                <p>{t("reloadWarning")}</p>
                            )
                            }
                        </select>
                    </div>


                    <div className='d-flex justify-content-center'>
                        <button type="submit" class="btn btn-primary btn-lg">{t("startToCreate")}</button>
                    </div>

                </form>
            )}

        </div>

    )
}
export default AdminCreateQuestion;




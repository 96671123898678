import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";
import 'quill/dist/quill.snow.css'
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Datatable from "../../../../Components/Datatable"
import Choice from "../QuestionListTypes/Choice"
import Match from "../QuestionListTypes/Match"
import VideoContent from "../QuestionListTypes/VideoContent"
import Write from "../QuestionListTypes/Write"
import RealMatch from "../QuestionListTypes/RealMatch"
import TrueFalse from "../QuestionListTypes/TrueFalse"
import Sentence from "../QuestionListTypes/Sentence"

export const QuestionListBySubject = () => {
    const [data, setData] = useState([]);
    const [deletedData, setdeletedData] = useState([]);

    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const params = useParams()

    const navigate = useNavigate();
    const columns = React.useMemo(
        () => [
            {
                Header: t('id'),
                Footer: t('id'),
                accessor: 'id',
            },
            {
                Header: t('contentType'),
                Footer: t('contentType'),
                accessor: 'contentType',
            },
            {
                Header: t('answer'),
                Footer: t('answer'),
                accessor: 'answer',
            },
            {
                Header: t('detail'),
                Footer: t('detail'),
                accessor: 'detail',
            }, {
                Header: t('notfyText'),
                Footer: t('notfyText'),
                accessor: 'notfyText',
            },
            {
                Header: t('editQuestion'),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-info btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t('edit')}
                        </button>

                    </div>
                ),
                id: 'actionEditCourse',
                Footer: t('editQuestion'),
            },

        ],
        [],
    )
    const deletedcolumns = React.useMemo(
        () => [
            {
                Header: t('id'),
                Footer: t('id'),
                accessor: 'id',
            },
            {
                Header: t('contentType'),
                Footer: t('contentType'),
                accessor: 'contentType',
            },
            {
                Header: t('answer'),
                Footer: t('answer'),
                accessor: 'answer',
            },
            {
                Header: t('detail'),
                Footer: t('detail'),
                accessor: 'detail',
            }, {
                Header: t('notfyText'),
                Footer: t('notfyText'),
                accessor: 'notfyText',
            },
            {
                Header: t('editQuestion'),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-info btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t('edit')}
                        </button>

                    </div>
                ),
                id: 'actionEditCourse',
                Footer: t('editQuestion'),
            },

        ],
        [],
    )

    const handleEdit = (row) => {
        window.location.href = "/dashboard/admin/editquestion/" + row['id'];
    }

    const onSubmit = async (e) => {
        try {
            let sort = await document.getElementById("sortId").value;
            let questiontype = await document.getElementById("contentType").value;
            let subject = params["subject"]
            let tag = await document.getElementById("tagId").value;
            navigate("/dashboard/admin/createquestiondetail/" + tag + "/" + subject + "/" + questiontype + "/" + sort);
        } catch (error) {
            alert(error.message);
        }
    }

    useEffect(() => {
        const fetchdataa = async () => {
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/ContentManager/GetListBySubject";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss,
                    params: {
                        subjectId: params["subject"]
                    }
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                if (res.data['data'][i]['isDeleted'] === false) {
                                    data.push({
                                        "id": res.data['data'][i]['id'],
                                        "contentType": res.data['data'][i]['contentType'],
                                        "contentStr": res.data['data'][i]['contentStr'],
                                        "answer": res.data['data'][i]['answer'],
                                        "detail": res.data['data'][i]['detail'],
                                        "notfyText": res.data['data'][i]['notfyText'],
                                        "sortId": res.data['data'][i]['sortId'],
                                        "tagId": res.data['data'][i]['tagId']
                                    })
                                }
                                else {
                                    deletedData.push({
                                        "id": res.data['data'][i]['id'],
                                        "contentType": res.data['data'][i]['contentType'],
                                        "contentStr": res.data['data'][i]['contentStr'],
                                        "answer": res.data['data'][i]['answer'],
                                        "detail": res.data['data'][i]['detail'],
                                        "notfyText": res.data['data'][i]['notfyText'],
                                        "sortId": res.data['data'][i]['sortId'],
                                        "tagId": res.data['data'][i]['tagId']
                                    })
                                }
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdataa();
    }, [])


    if (!loading) {
        return (
            <div>
                {
                    data && data.map((dt) => {
                        if (dt['contentType'] === "choice") {
                            return (
                                <div style={{ border: "1px solid black", padding: "2%" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <h5>Çoktan Seçmeli</h5>
                                    </div>
                                    <Choice data={dt} />
                                </div >
                            )
                        }
                        else if (dt['contentType'] === "match") {
                            return (
                                <div style={{ border: "1px solid black", padding: "2%" }}>
                                    <div style={{ textAlign: "center" }}> <h5>unscramble</h5> </div>
                                    <Match data={dt} />
                                </div >
                            )
                        }
                        else if (dt['contentType'] === "video") {
                            return (
                                <div style={{ border: "1px solid black", padding: "2%" }}>
                                    <div style={{ textAlign: "center" }}> <h5>VİDEO DERS</h5> </div>
                                    <VideoContent data={dt} />
                                </div >
                            )
                        }
                        else if (dt['contentType'] === "write") {
                            return (
                                <div style={{ border: "1px solid black", padding: "2%" }}>
                                    <div style={{ textAlign: "center" }} > <h5>Write</h5> </div>
                                    <Write data={dt} />
                                </div >
                            )
                        }
                        else if (dt['contentType'] === "sentence") {
                            return (
                                <div style={{ border: "1px solid black", padding: "2%" }}>
                                    <div style={{ textAlign: "center" }} > <h5>Cümle oluşturma</h5> </div>
                                    <Sentence data={dt} />
                                </div >
                            )
                        }
                        else if (dt['contentType'] === "realmatch") {
                            return (
                                <div style={{ border: "1px solid black", padding: "2%" }}>
                                    <div style={{ textAlign: "center" }} > <h5>match</h5> </div>
                                    <RealMatch data={dt} />
                                </div >
                            )
                        }
                        else if (dt['contentType'] === "truefalse") {
                            return (
                                <div style={{ border: "1px solid black", padding: "2%" }}>
                                    <div style={{ textAlign: "center" }} > <h5>truefalse</h5> </div>
                                    <TrueFalse data={dt} />
                                </div >
                            )
                        }
                    })

                }
            </div >
        )

    }

}
export default QuestionListBySubject;




import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../context/AuthContext';
import Datatable from "../../../pages/Dashboard/Admin/Components/Datatable";
import axios from "axios";

const PaymentMethodList = (props) => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const [banksdata, setbanksdata] = useState([]);

    const getBanks = async () => {
        setLoading(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/User/GetGateways";
            await axios({
                method: 'GET',
                url: url,
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            let a = "";
                            if (res.data['data'][i]['isActive']) {
                                banksdata.push({
                                    "id": res.data['data'][i]['id'],
                                    "gatewayName": res.data['data'][i]['gatewayName'],
                                    "typeName": res.data['data'][i]['typeName'],
                                    "typeDescription": res.data['data'][i]['typeDescription'],
                                })
                            }
                        }
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
        setLoading(false);
    }
    const columnsbanks = React.useMemo(
        () => [
            {
                Header: t("id"),
                Footer: t("id"),
                accessor: 'id',
            },
            {
                Header: t("paymentmethod"),
                Footer: t("paymentmethod"),
                accessor: 'typeName',
            }
            ,
            {
                Header: t("bankName"),
                Footer: t("bankName"),
                accessor: 'gatewayName',
            },

            {
                Header: t('ibanNreceiver'),
                Footer: t('ibanNreceiver'),
                accessor: 'typeDescription',
            }



        ],
        [],
    )
    useEffect(() => {
        getBanks();
    }, []);

    return (
        <div>
            <h1>{t("paymentmethodlist")}</h1>

            {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
            {!loading && (
                <div>
                    <Datatable data={banksdata} columns={columnsbanks} tableBaslik={t("paymentmethodlist")} />
                </div>
            )}
            <hr />
        </div>
    );
};

export default PaymentMethodList;
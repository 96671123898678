import React from 'react';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../context/AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ManagerSidebarDetail = (props) => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    return (
        <ul class="list-unstyled components ">
            <li id="school">
                <div>
                    <a href="#" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#schools"
                        aria-controls="schools"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-users" />
                        </b>

                        <b className="forsidebar">&nbsp;{t('school')}</b>

                    </a>
                    <ul class="collapse list-unstyled" id="schools">
                        <li>
                            <a href={"/dashboard/school/editschool/" + user.userDetail.schoolId}>
                                <b className="forsidebar">
                                    {t('editSchool')}
                                </b>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <br />
            <li id="schoolteacher">
                <div>
                    <a href="#" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#teachers"
                        aria-controls="teachers"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-users" />
                        </b>

                        <b className="forsidebar">&nbsp;{t('teachers')}</b>

                    </a>
                    <ul class="collapse list-unstyled" id="teachers">


                        <li>
                            <a href="/dashboard/school/teachercreate">
                                <b className="forsidebar">
                                    {t('createteacher')}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/school/teacherlist">
                                <b className="forsidebar">
                                    {t('teachersList')}
                                </b>
                            </a>
                        </li>
                    </ul>


                </div>

            </li>
            <br />
            <li id="classesteacher">
                <div>
                    <a href="#" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#class"
                        aria-controls="class"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-users" />
                        </b>
                        <b className="forsidebar">
                            &nbsp;
                            {t("classes")}
                        </b>
                    </a>
                    <ul class="collapse list-unstyled" id="class">
                        <li>
                            <a href="/dashboard/school/classlist">
                                <b><FontAwesomeIcon icon="fas fa-user-friends" /></b>
                                <b className="forsidebar">
                                    {t("classlist")}
                                </b>
                            </a>
                        </li>

                        <li>
                            <a href="/dashboard/school/createclass">
                                <b>
                                    <FontAwesomeIcon icon="fas fa-user" />
                                </b>
                                <b className="forsidebar">
                                    {t("createclass")}
                                </b>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <br />
            <li id="studentsteacher">
                <div>
                    <a href="#" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#student"
                        aria-controls="student"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-users" />
                        </b>
                        <b className="forsidebar">
                            &nbsp;
                            {t("students")}
                        </b>
                    </a>
                    <ul class="collapse list-unstyled" id="student">
                        <li>
                            <a href="/dashboard/teacher/studentcreate">
                                <b className="forsidebar">
                                    {t("createstudent")}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/teacher/studentlist">
                                <b className="forsidebar">
                                    {t("studentList")}
                                </b>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>

        </ul>
    );
};

export default ManagerSidebarDetail;
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAuth } from '../../../../../../../context/AuthContext';

const Tag = (props) => {
    const { t, i18n } = useTranslation()
    const [tags, setTags] = useState([]);
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchdata = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/TagManager/GetTags";

                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {
                    if (res.status === 200) {
                        setTags(res.data['data'])
                        for (let i = 0; i < res.data['data'].length; i++) {
                            if (res.data['data'][i]['isDeleted'] === false) {
                            }
                        }
                        if (res.data['success']) {

                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                alert(err.message)
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdata();
    }, []);

    return (
        <div>
            {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
            {!loading && (
                <div class="mb-3">
                    <label for="tagId" class="form-label">{t("selecttag")}</label>
                    <select id="tagId" class="form-select" aria-label="Default select example" required>
                        <option key="0" >{t("selecttag")}</option>
                        {tags ? (
                            tags && tags.map((dt) => {
                                return (
                                    <option key={dt.id} value={dt.id}>{dt.tagName}</option>
                                );
                            })
                        ) : (
                            <p>{t("reloadPage")}</p>
                        )
                        }
                    </select>
                </div>
            )}
        </div>
    );
};

export default Tag;
import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { useTranslation } from "react-i18next";

const Sentence = (props) => {
    const [obj, setObj] = useState([]);
    const [answ, setAnsw] = useState([]);
    const [url, setUrl] = useState([]);
    const [questionId, setQuestionId] = useState([]);
    const onClick = () => {
        window.location.href = "/dashboard/admin/editquestion/" + questionId;
    }
    function setCharAt(str, index, chr) {
        if (index > str.length - 1) return str;
        return str.substring(0, index) + chr + str.substring(index + 1);
    }
    useEffect(() => {
        setQuestionId(JSON.parse(props.data.id));


        setObj(JSON.parse(props.data.contentStr))
        var question = JSON.parse(props.data.contentStr)['text']

        let ans = JSON.parse(props.data.detail);
        for (var i = 0; i < ans.length; i++) {
            var txt = document.createElement("b");
            txt.textContent = question.split("_")[i]
            document.getElementById('question').appendChild(txt);

            //document.getElementById('question').appendChild(document.createTextNode(question.split("_")[i]));
            var element = document.createElement("div");
            //element.classList.add('mb-3');
            var element2 = document.createElement("select")
            element2.id = ans[i].id;
            element2.required = true;
            //element2.classList.add('form-select');
            var element3 = document.createElement("option");
            element3.text = "seçim yapınız";
            element3.key = 0;
            element2.appendChild(element3);
            for (let j = 0; j < ans[i]['service'].length; j++) {
                var element4 = document.createElement("option");
                element4.text = ans[i]['service'][j];
                element4.value = ans[i]['service'][j];
                element4.key = ans[i]['service'][j];

                element2.appendChild(element4);
            }
            //element.appendChild(element2);
            txt.appendChild(element2)
        }
        var txt = document.createElement("b");
        txt.textContent = question.split("_")[ans.length]
        document.getElementById('question').appendChild(txt);


        setAnsw(JSON.parse(props.data.detail));
        setUrl("http://api.heryerdeingilizce.com/" + JSON.parse(props.data.contentStr)['url']);
    }, []);

    return (
        <div>
            <hr />
            <div style={{ alignItems: "center", textAlign: "center" }}>
                {obj['type'] === "sound" ? (
                    <ReactAudioPlayer
                        src={url}
                        autoPlay
                        controls
                    />
                ) : (
                    <div></div>
                )}
                {obj['type'] === "video" ? (
                    <iframe src={url} width="100%" height="580" frameborder="4" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                ) : (
                    <div></div>
                )}
                {obj['type'] === "image" ? (
                    <div style={{ width: "70%", marginLeft: "auto", marginRight: "auto " }}>
                        <img src={url} />
                    </div>
                ) : (
                    <div></div>
                )}



            </div>
            <br />
            <div class="d-flex justify-content-center" style={{ fontSize: 40 }}>
                <form  >
                    <div id='question'>

                    </div>
                </form>
            </div>
            <br />

            <div className='d-flex justify-content-center'>
                <button className='btn btn-info' onClick={onClick}>Soruyu Düzenle</button>
            </div>
        </div>
    );
};

export default Sentence;
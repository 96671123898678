import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";
import GeneralRequestHelper from '../../../../../../Helpers/GeneralRequestHelper';

export const AdminCreateCourse = () => {
    const [sections, setSections] = useState('');
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [SortIdValue, setSortIdValue] = useState([]);
    const [imagePath, setImagePath] = useState(null);


    const handleChange = async (event) => {
        const formData = new FormData();
        formData.append('FormFile', event.target.files[0]);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            const url = 'https://api.heryerdeingilizce.com/api/admin/ContentFile/UploadFile';
            await axios({
                method: 'Post',
                url: url,
                params: {
                    mediaType: "photo"
                },
                data: formData,
                headers: headerss
            }).then(res => {
                alert("Yüklendi!")
                let url = "https://api.heryerdeingilizce.com/" + res.data['data']['path'];
                setImagePath(url)
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    const getCourses = async () => {
        try {
            let params = {
                id: document.getElementById("sectionId").value
            };
            await GeneralRequestHelper("/api/admin/CourseManager/GetCoursesBySection", "get", params, user.token).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        if (res.data['data'].length !== 0) {
                            setSortIdValue(Math.max.apply(Math, res.data['data'].map(function (o) { return o.sortId; })) + 1)
                        }
                        else {
                            setSortIdValue(1)
                        }
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    const handleSelectChange = async (event) => {
        getCourses()
    }
    const onSubmit = async (e) => {
        e.preventDefault();
        if (!imagePath) {
            setImagePath("https://api.heryerdeingilizce.com/photo/91ebd6aa-6a51-4dcc-ad27-5633dbddd6e6.png")
        }
        if (SortIdValue === 0) {
            window.location.reload();
        }
        try {
            const data = {
                courseName: document.getElementById("courseName").value,
                courseDesc: document.getElementById("courseDesc").value,
                sectionId: document.getElementById("sectionId").value,
                sortId: SortIdValue,
                featuredImage: imagePath,
                isActive: true
            }
            await GeneralRequestHelper("/api/admin/CourseManager/Create", "post", data, user.token).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            window.location.reload();
                        })
                    }
                    else {
                        setMsg(res.data['message']);
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            timer: 3000,
                            confirmButtonText: `Tamam`
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    const getSections = async () => {
        setLoading(true);
        try {
            await GeneralRequestHelper("/api/admin/SectionManager/GetList", "get", "", user.token).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        setSections(res.data['data'])
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
        setLoading(false);
    }

    useEffect(() => {
        setSortIdValue(0)
        getSections();
    }, [])



    return (
        <div>
            <div style={{ backgroundColor: "white", padding: "3%" }}>
                <div style={{ textAlign: "center" }}>
                    <h1>{t("createCourse")}</h1>
                </div>
                <h3>{msg}</h3>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <form onSubmit={onSubmit} >
                        <div class="mb-3">
                            <label for="courseName" class="form-label">{t("courseName")}</label>
                            <textarea class="form-control" id="courseName" required />
                        </div>
                        <div class="mb-3">
                            <label for="courseDesc" class="form-label">{t("courseDescription")}</label>
                            <textarea class="form-control" id="courseDesc" required />
                        </div>

                        <div class="mb-3">
                            <label for="sectionId" class="form-label">{t("selectSection")}</label>
                            <select id="sectionId" class="form-select" onChange={handleSelectChange}>
                                {sections ? (
                                    sections && sections.map((dt) => {
                                        return (
                                            <option key={dt.id} value={dt.id}>{dt.sectionName} -- {dt.sectionDescription}</option>
                                        );
                                    })
                                ) : (
                                    <p>{t("reloadWarning")}</p>
                                )
                                }
                            </select>
                        </div>

                        <div class="mb-3">
                            <label for="schoollogo" class="form-label">{t("image")}</label>
                            <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handleChange} id="imagePath1" name="schoollogo" />
                        </div>


                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                        </div>

                    </form>
                )}
            </div>
        </div>
    )
}
export default AdminCreateCourse;
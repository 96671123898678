import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useAuth } from '../../../../../../../context/AuthContext';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import GeneralRequestHelper from '../../../../../../../Helpers/GeneralRequestHelper';
import SubmitButton from '../UI/SubmitButton';
import InfoInput from '../UI/InfoInput';
import CorrectAnswer from '../UI/CorrectAnswer';
import QuestionTextInput from '../UI/QuestionTextInput';
import AddContentQuestion from '../UI/AddContentQuestion';
import Tag from '../UI/Tag';
import TitleArea from '../UI/TitleArea';
import AnswerForm from '../UI/AnswerForm';
const Choice = (props) => {
    const { user } = useAuth();
    const params = useParams()
    const navigate = useNavigate();
    const [msg, setMsg] = useState([]);
    const [serviceList, setServiceList] = useState([{ service: "" }]);
    const [imagePath, setImagePath] = useState(null);
    const [videoPath, setVideoPath] = useState(null);
    const [soundPath, setSoundPath] = useState(null);
    const [data, setData] = useState([]);


    const handletype2 = async (e) => {
        e.preventDefault();
        var x = document.getElementById("correctAnswer").value;
        let answer = Number(x) - 1;
        var question;
        let questiontext = document.getElementById("questionText").value;
        if (imagePath) {
            question = {
                type: "image",
                url: imagePath,
                text: questiontext
            }
        }
        else if (soundPath) {
            question = {
                type: "sound",
                url: soundPath,
                text: questiontext
            }
        }
        else if (videoPath) {
            question = {
                type: "video",
                url: videoPath,
                text: questiontext
            }
        }
        else {
            question = {
                type: "text",
                url: "",
                text: questiontext
            }
        }
        var stringquestion = JSON.stringify(question)
        var obj = []
        for (let i = 0; i < serviceList.length; i++) {
            let list = {
                id: i,
                service: serviceList[i]['service'],
                photo: serviceList[i]['photo'],
            }
            obj.push(list)
        }
        var answertext = JSON.stringify(obj)
        var info = document.getElementById("infoBoxText").value;
        try {

            var questionn = {
                isDeleted: data.isDeleted,
                tagId: document.getElementById("tagId").value,
                sortId: data.sortId,
                contentType: "choice",
                subjectId: params["subject"],
                contentStr: stringquestion,
                detail: answertext,
                notfyText: info,
                answer: answer.toString(),
                id: params['sort']
            }
            await GeneralRequestHelper("/api/admin/ContentManager/Update", "post", questionn, user.token).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Soru listesi sayfasına Dön`,
                            timer: 5000,
                        }).then(() => {
                            navigate("/dashboard/admin/questionlist");
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            alert(err.message)
            setMsg(err.message);
        }
    }
    const getQuestion = async () => {
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/ContentManager/GetById";
            await axios({
                method: 'GET',
                url: url,
                headers: headerss,
                params: {
                    id: params["sort"]
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        setData(res.data['data'])
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
    }

    useEffect(() => {
        getQuestion();

    });

    return (
        <div>
            <TitleArea msg={msg} text={"Çoktan Seçmeli Oluşturma Sayfası"} />
            <Tag />
            <AddContentQuestion setMsg={setMsg} setImagePath={setImagePath} setVideoPath={setVideoPath} setSoundPath={setSoundPath} />
            <form onSubmit={handletype2} >
                <QuestionTextInput />
                <CorrectAnswer text="1/2/3/4/5 olarak hangi seçenek olduğunu belirtiniz lütfen" />
                <InfoInput />
                <AnswerForm setServiceList={setServiceList} serviceList={serviceList} file={true} />
                <SubmitButton />
            </form>
            <hr />
            <hr />

        </div>
    );
};

export default Choice;
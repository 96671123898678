import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../context/AuthContext';
import Datatable from "../../../pages/Dashboard/Admin/Components/Datatable";
import axios from "axios";
import Swal from 'sweetalert2'
const StudentTicket = (props) => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    const [loadingticket, setLoadingticket] = useState(true);
    const [msg, setMsg] = useState([]);
    const [ticketList, setTicketList] = useState([]);
    const [msgstudentticket, setMsgstudentticket] = useState([]);

    const columnsticket = React.useMemo(
        () => [
            {
                Header: t("id"),
                Footer: t("id"),
                accessor: 'id',
            },
            {
                Header: t("ticketNo"),
                Footer: t("ticketNo"),
                accessor: 'ticketCode',
            }
            ,
            {
                Header: t("ticketCreatedDate"),
                Footer: t("ticketCreatedDate"),
                accessor: 'creationDate',
            },

            {
                Header: t('ticketTitle'),
                Footer: t('ticketTitle'),
                accessor: 'title',
            }
            , {
                Header: t('ticketstate'),
                Footer: t('ticketstate'),
                accessor: 'status',
            },
            {
                Header: t('ticketdetail'),
                Footer: t('ticketdetail'),
                accessor: 'message',
            }, {
                Header: t("showdetails"),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handlegetdetails(originalRow)}
                        >
                            {t("showdetails")}
                        </button>
                    </div>
                ),
                id: 'actionDelete',
                Footer: t("showdetails"),
            },
        ],
        [],
    )
    const fetchTickets = async () => {
        setLoadingticket(true)
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/user/Opt/TicketList";
            await axios({
                method: 'GET',
                url: url,
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            let a = "";
                            if (res.data['data'][i]['status'] === "open") {
                                a = t('openedTicket');
                            }
                            else if (res.data['data'][i]['status'] === "waiting") {
                                a = t('waitingticket');
                            }
                            else if (res.data['data'][i]['status'] === "answered") {
                                a = t('answeredTickets');
                            }
                            ticketList.push({
                                "id": res.data['data'][i]['id'],
                                "ticketCode": res.data['data'][i]['ticketCode'],
                                "creationDate": res.data['data'][i]['creationDate'],
                                "title": res.data['data'][i]['title'],
                                "message": res.data['data'][i]['message'],
                                "status": a,
                            })
                        }
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
        setLoadingticket(false)

    }
    const handlegetdetails = (row) => {
        window.location.href = "profile/ticketdetail/" + row['ticketCode'];
    }

    const handlestudentcreateticket = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/user/Opt/CreateTicket";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    title: document.getElementById("studenttickettitle").value,
                    detail: document.getElementById("studentticketdetail").value,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Destek talebiniz oluşturulmuştur!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        Swal.fire({
                            title: "Destek talebiniz oluşturulamadı!",
                            icon: "error",
                            confirmButtonText: `Tamam`
                        });
                        setMsgstudentticket(res.data['message']);
                    }
                }
                else {
                    setMsgstudentticket("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsgstudentticket(err.message);
        }

    }
    useEffect(() => {
        fetchTickets();
    }, []);


    return (

        <div style={{ overflowX: "auto", border: "1px solid black", padding: "2px" }}>
            {msg}
            {loadingticket && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
            {!loadingticket && (
                <div>
                    <Datatable data={ticketList} columns={columnsticket} tableBaslik={t("ticketlist")} />
                </div>
            )}
            <br />
            <hr />
            <div style={{ textAlign: "center", backgroundColor: "white" }}>
                <h4>{t("createticket")}</h4>
                <h6> {msgstudentticket} </h6>
                <form onSubmit={handlestudentcreateticket} style={{ padding: "2%" }} >
                    <div class="mb-3">
                        <label for="studenttickettitle" class="form-label">{t("ticketTitle")}</label>
                        <textarea class="form-control" id="studenttickettitle" required />
                    </div>
                    <div class="mb-3">
                        <label for="studentticketdetail" class="form-label">{t("ticketText")}</label>
                        <textarea class="form-control" id="studentticketdetail" required />
                    </div>
                    <div className='d-flex justify-content-center'>
                        <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                    </div>
                </form>
                <br />
            </div>
        </div>
    );
};

export default StudentTicket;
import React, { useState, useEffect } from 'react';
import QuestionContents from './UI/QuestionContents';

const RealMatch = (props) => {

    const [question, setQuestion] = useState([]);
    const [questionTitle, setQuestionTitle] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [questionText, setQuestionText] = useState([]);
    const [userAnswers, setUserAnswers] = useState([]);
    const [userAnswerss, setUserAnswerss] = useState([]);
    const [questionId, setQuestionId] = useState([]);



    function allowDrop(ev) {
        ev.preventDefault();
    }

    function drag(ev) {
        ev.dataTransfer.setData("text", ev.target.id);
    }

    function drop(ev) {
        ev.preventDefault()
        // kutunun divi = ev.target.id
        document.getElementById(ev.target.id).innerHTML = ""
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        let kutudiv = ev.target.id;
        let resimdiv = data.split(".")[0];
        ev.target.appendChild(document.getElementById(data));
        userAnswers.push(kutudiv);
        userAnswerss.push(resimdiv);

        //data resmin divi

    }
    const onClick = () => {
        window.location.href = "/dashboard/admin/editquestion/" + questionId;
    }


    useEffect(() => {
        setQuestionId(JSON.parse(props.data.id));
        var questionn = JSON.parse(props.data.contentStr);
        var questionTitlee = questionn['text'].split('__')[0];
        let questionTextt = questionn['text'].split('__')[1];
        let answerss = JSON.parse(props.data.detail);
        setQuestion(questionn);
        setQuestionTitle(questionTitlee);
        setQuestionText(questionTextt);
        setAnswers(answerss);
    }, []);


    return (
        <div>
            <hr />
            <div style={{ alignItems: "center", textAlign: "center" }}>
                <QuestionContents question={question} />
                <h5>
                    {questionTitle}
                </h5>
                <br />
                <h6>
                    {questionText}
                </h6>
            </div>
            <div class="d-flex justify-content-center" style={{ fontSize: 20, textAlign: "center" }}>
                <div className="row">
                    <div className="col-md-6">
                        <ul>
                            {answers && answers.map((dt) => {
                                if (dt.type === 0)
                                    return (
                                        <li>
                                            {dt['service'] !== " " ? (
                                                <div id={dt.id + 1} style={{ width: "250", height: "150", border: "1px solid black" }} onDrop={e => drop(e)} onDragOver={e => allowDrop(e)} > {dt['service']}</div>
                                            ) : null}

                                            {dt['photo'] ? (
                                                <img value={dt.id} id={dt.id + 1 + ".img"} src={dt['photo']} draggable="true" onDragStart={e => drag(e)} width="250" height="150" />

                                            ) : (
                                                <div></div>
                                            )}
                                            <br /><hr />
                                        </li>
                                    )
                            })}
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <ul>
                            {answers && answers.map((dt) => {
                                if (dt.type === 1)
                                    return (
                                        <li>
                                            {dt['service'] !== " " ? (
                                                <div id={dt.id + 1} style={{ width: "250", height: "150", border: "1px solid black" }} onDrop={e => drop(e)} onDragOver={e => allowDrop(e)} > {dt['service']}</div>

                                            ) : null}
                                            {dt['photo'] ? (
                                                <img value={dt.id} id={dt.id + 1 + ".img"} src={dt['photo']} draggable="true" onDragStart={e => drag(e)} width="250" height="150" />
                                            ) : (
                                                <div></div>
                                            )}
                                            <br />
                                            <hr />

                                        </li>
                                    )
                            })}
                        </ul>
                    </div>

                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <button className='btn btn-info' onClick={onClick}>Soruyu Düzenle</button>
            </div>
        </div>
    );
};

export default RealMatch;
import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../../context/AuthContext';
import Swal from 'sweetalert2'
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import QuestionContents from './UI/QuestionContents';
const RealMatch = (props) => {
    const { t, i18n } = useTranslation()

    const [questionNumber, setquestionNumber] = useState([]);
    const [msg, setMsg] = useState([]);
    const { user } = useAuth();
    const params = useParams();
    const [question, setQuestion] = useState([]);
    const [questionTitle, setQuestionTitle] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [questionText, setQuestionText] = useState([]);
    const [userAnswers, setUserAnswers] = useState([]);
    const [userAnswerss, setUserAnswerss] = useState([]);

    const Request = async (userAnswer) => {
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/user/Content/Answer";
            await axios({
                method: 'GET',
                url: url,
                params: {
                    questionId: props.questionNumber,
                    answer: userAnswer.toString()
                },
                headers: headerss
            }).then(res => {
                if (res.data['success']) {
                    let audio = new Audio("https://api.heryerdeingilizce.com/sound/d6350096-7457-46bc-bcaa-313a33970558.mp4")
                    audio.play();
                    localStorage.setItem("count", 0)
                    Swal.fire({
                        title: "Başırılı!",
                        icon: "success",
                        confirmButtonText: `Tamam`,
                        timer: 3000,
                    }).then(() => {
                        let newurl = Number(params['id']) + 1;
                        window.location.href = "/dashboard/user/course/question/" + params['subjectid'] + "/" + newurl;

                    });
                }
                else {
                    if (res['data']['message'] === "Bu soru daha önce çözülmüş") {
                        localStorage.setItem("count", 0)

                        Swal.fire({
                            title: "Hata!",
                            text: "Bu soru daha önce çözülmüş",
                            icon: "error",
                            confirmButtonText: `Tamam`,
                        }).then(() => {
                            let newurl = Number(params['id']) + 1;
                            window.location.href = "/dashboard/user/course/question/" + params['subjectid'] + "/" + newurl;
                        });


                    }
                    else {
                        let audio = new Audio("https://api.heryerdeingilizce.com/sound/f174007c-0602-4455-8463-ae6a3285f02c.mp4")
                        audio.play();
                        localStorage.setItem("count", 0)
                        Swal.fire({
                            title: "Hatalı Cevap!",
                            text: props.data.notfyText,
                            icon: "error",
                            confirmButtonText: `Tamam`,
                        }).then(() => {
                            let newurl = Number(params['id']) + 1;
                            window.location.href = "/dashboard/user/course/question/" + params['subjectid'] + "/" + newurl;
                        });
                    }

                }
            });
        }
        catch (err) {
            setMsg(err.message);
        }
    }

    function allowDrop(ev) {
        ev.preventDefault();
    }

    function drag(ev) {

        ev.dataTransfer.setData("text", ev.target.id);
    }

    function drop(ev) {
        let position = ev.dataTransfer.getData("text").search("snd");
        let str = ev.target.id
        let box = str.substring(0, str.length - 1);
        // alert(ev.dataTransfer.getData("text"))
        // alert(box)
        if (position === -1) {
            let pos = ev.dataTransfer.getData("text").search("img");
            if (pos === -1) {
                let gelenColId = ev.dataTransfer.getData("text") + "s"
                let cominginside = document.getElementById(gelenColId)
                document.getElementById(ev.dataTransfer.getData("text")).style.visibility = "hidden"
                document.getElementById(box).style.width = "300px"
                document.getElementById(box).appendChild(cominginside)
            }
            else {
                let gelenColId = ev.dataTransfer.getData("text")

                let cominginside = document.getElementById(gelenColId)
                document.getElementById(box).style.width = "310px"

                document.getElementById(box).appendChild(cominginside)
            }

            ev.preventDefault();
        }
        else {
            document.getElementById(box).style.width = "470"
            document.getElementById(ev.dataTransfer.getData("text")).style.padding = "35px 0"
            document.getElementById(box).appendChild(document.getElementById(ev.dataTransfer.getData("text")))
            ev.preventDefault();
        }
        let comedive = ev.dataTransfer.getData("text").substring(0, (ev.dataTransfer.getData("text").length) - (ev.dataTransfer.getData("text").length - 1));
        let kutudiv = str.substring(0, (str.length) - (str.length - 1));

        userAnswers.push(kutudiv);
        userAnswerss.push(comedive);
    }




    const answerRealMatchHandle = async (e) => {
        let value = localStorage.getItem("count")

        let correctAnswer = JSON.parse(props.data.answer);
        let answerCorrect = true

        for (var i = 0; i < userAnswers.length; i++) {
            if (userAnswers[i] === userAnswerss[i]) {
                answerCorrect = true;
            }
            else {
                answerCorrect = false;
                break;
            }
        }
        const fetchdata = async () => {
            if (parseInt(value) === 0) {
                if (answerCorrect) {
                    setMsg(Request(correctAnswer))
                }
                else {
                    let audio = new Audio("https://api.heryerdeingilizce.com/sound/f174007c-0602-4455-8463-ae6a3285f02c.mp4")
                    audio.play();
                    Swal.fire({
                        title: "Tekrar Deneyiniz!",
                        text: props.data.notfyText,
                        icon: "warning",
                        confirmButtonText: `Tamam`,
                    }).then(() => {
                        window.location.reload();
                    });
                }
            }
            else if (parseInt(value) === 1) {
                setMsg(Request("userAnswer"))
            }
            localStorage.setItem("count", parseInt(value) + 1)
        }
        e.preventDefault();
        fetchdata();
    }


    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array
    }
    useEffect(() => {

        let value = localStorage.getItem("count")
        if (value === null) {
            localStorage.setItem("count", 0)
        }

        var questionn = JSON.parse(props.data.contentStr);
        var questionTitlee = questionn['text'].split('__')[0];
        let questionTextt = questionn['text'].split('__')[1];
        let answerss = JSON.parse(props.data.detail);
        answerss = shuffleArray(answerss)
        setQuestion(questionn);
        setQuestionTitle(questionTitlee);
        setQuestionText(questionTextt);
        setAnswers(answerss);
    }, []);


    return (
        <div>
            <br />
            <div style={{ alignItems: "center", textAlign: "center" }}>
                <QuestionContents question={question} />

                <br />
                <h3>
                    {questionTitle}
                </h3>
                <br /> <br />
                <h4>
                    {questionText}
                </h4>
                <br /><br />
            </div>
            <div class="d-flex justify-content-center" style={{ fontSize: 20, textAlign: "center" }}>
                <form onSubmit={answerRealMatchHandle} >
                    <div className="row">
                        <div className="col-md-3">
                            <ul>
                                {answers && answers.map((dt) => {
                                    if (dt.type === 0)
                                        return (
                                            <li >
                                                {dt['service'] !== " " ? (
                                                    <div className='row' id={dt.id + "0text"} style={{ width: "150px", height: "150px", border: "1px solid black" }} onDrop={e => drop(e)} onDragOver={e => allowDrop(e)} >
                                                        <div id={dt.id + "0texts"} style={{ padding: "60px 0", width: "145px" }}>  {dt['service']}</div>
                                                    </div>
                                                ) : null}
                                                {dt['photo'] ? (
                                                    <div className='row' id={dt.id + "0.img"} style={{ width: "150px", height: "150px", border: "1px solid black" }} onDrop={e => drop(e)} onDragOver={e => allowDrop(e)} >
                                                        <img style={{ width: "150px", height: "150px" }} id={dt.id + "0.imgs"} src={dt['photo']} />
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )}
                                                {dt['photourl'] ? (
                                                    <div className='row' id={dt.id + "0.imgurl"} style={{ width: "150px", height: "150px", border: "1px solid black" }} onDrop={e => drop(e)} onDragOver={e => allowDrop(e)} >
                                                        <img style={{ width: "150px", height: "150px" }} id={dt.id + "0.imgurls"} src={dt['photourl']} />
                                                    </div>) : (
                                                    <div></div>
                                                )}
                                                {dt['sound'] ? (
                                                    <div id={dt.id + "0.snd"} style={{ width: "auto", border: "1px solid black" }} onDrop={e => drop(e)} onDragOver={e => allowDrop(e)} >
                                                        <ReactAudioPlayer
                                                            id={dt.id + "0.snds"}
                                                            src={dt['sound']}
                                                            controls
                                                        />
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )}
                                                {dt['soundurl'] ? (
                                                    <div id={dt.id + "0.sndurl"} style={{ width: "auto", border: "1px solid black" }} onDrop={e => drop(e)} onDragOver={e => allowDrop(e)} >
                                                        <ReactAudioPlayer
                                                            id={dt.id + "0.sndurls"}

                                                            src={dt['soundurl']}
                                                            controls
                                                        />
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )}
                                                <br /><hr />
                                            </li>
                                        )
                                })}
                            </ul>
                        </div>
                        <div className='col-md-6'>

                        </div>
                        <div className="col-md-3">
                            <ul>
                                {answers && answers.map((dt) => {
                                    if (dt.type === 1)
                                        return (
                                            <li>
                                                {dt['service'] !== " " ? (
                                                    <div className='row' id={dt.id + "1text"} style={{ width: "150px", height: "150px", }} draggable="true" onDragStart={e => drag(e)} >
                                                        <div id={dt.id + "1texts"} style={{ border: "1px solid black", padding: "60px 0", width: "145px" }}>  {dt['service']}</div>
                                                    </div>

                                                ) : null}
                                                {dt['photo'] ? (
                                                    <div id={dt.id + "1.img"} style={{ width: "150px", height: "150px" }} draggable="true" onDragStart={e => drag(e)}>
                                                        <img style={{ width: "150px", height: "150px", border: "1px solid black" }} id={dt.id + "1.imgs"} src={dt['photo']} />
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )}
                                                {dt['photourl'] ? (
                                                    <div id={dt.id + "1.imgurl"} style={{ width: "150px", height: "150px" }} draggable="true" onDragStart={e => drag(e)}>
                                                        <img style={{ width: "150px", height: "150px", border: "1px solid black" }} id={dt.id + "1.imgurls"} src={dt['photourl']} />
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )}
                                                {dt['sound'] ? (
                                                    <div id={dt.id + "1.snd"} style={{ width: "310px", border: "1px solid black" }} draggable="true" onDragStart={e => drag(e)}>
                                                        <ReactAudioPlayer
                                                            src={dt['sound']}
                                                            id={dt.id + "1.snds"}
                                                            controls
                                                        />
                                                    </div>
                                                ) : (
                                                    <div></div>
                                                )}
                                                {dt['soundurl'] ? (
                                                    <div id={dt.id + "1.sndurl"} style={{ width: "200px", border: "1px solid black" }} draggable="true" onDragStart={e => drag(e)}>
                                                        <ReactAudioPlayer
                                                            id={dt.id + "1.sndurls"}
                                                            width="148px"
                                                            src={dt['soundurl']}
                                                            controls
                                                        />
                                                    </div>

                                                ) : (
                                                    <div></div>
                                                )}
                                                <br />
                                                <hr />

                                            </li>
                                        )
                                })}
                            </ul>
                        </div>

                    </div>
                    <br /><br />
                    {props.answered ? (
                        <h4> cevaplanmış <i>{props.realanswer}</i> </h4>
                    ) : (
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t("saveAndContinue")}</button>
                        </div>
                    )}
                </form>
            </div>
            <div class="d-flex justify-content-center">
            </div>
            <br /><hr />
        </div>
    );
};

export default RealMatch;
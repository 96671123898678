import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import GeneralRequestHelper from '../../../../../../../Helpers/GeneralRequestHelper';
import { useNavigate } from "react-router-dom";

export const AdminEditQuestion = () => {
    const [loading, setLoading] = useState(true);
    const [loadingQuestion, setLoadingQuestion] = useState(true);

    const [loadingSubjects, setLoadingSubjects] = useState(true);
    const [loadingCourses, setLoadingCourses] = useState(true);
    const [loadingTags, setLoadingTags] = useState(true);
    const navigate = useNavigate();

    const { t } = useTranslation();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const params = useParams()
    const [data, setData] = useState([]);
    const [tags, setTags] = useState([]);
    const [courses, setCourses] = useState([]);
    const [subjects, setSubjects] = useState([]);

    const onClick = async (e) => {
        let newData = data;
        newData.isDeleted = true
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/ContentManager/Update";
            await axios({
                method: 'Post',
                url: url,
                data: newData,
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            window.location.href = "/dashboard/admin/questionlist";

                        });
                    }
                    else {
                        setMsg(res.data['message']);
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            timer: 3000,
                            confirmButtonText: `Tamam`
                        }).then(() => {
                            window.location.href = "/dashboard/admin/questionlist";
                        });
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    const createAgain = async (e) => {
        window.location.href = "/dashboard/admin/createquestionbysortid/" + params['id'];

    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            var questionn = {
                isDeleted: data.isDeleted,
                tagId: document.getElementById("tagId").value,
                sortId: data.sortId,
                contentType: "choice",
                subjectId: document.getElementById("subjectId").value,
                contentStr: data.contentStr,
                detail: data.detail,
                notfyText: data.notfyText,
                answer: data.answer,
                id: params['sort']
            }


            await GeneralRequestHelper("/api/admin/ContentManager/Update", "post", questionn, user.token).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Soru listesi sayfasına Dön`,
                            timer: 5000,
                        }).then(() => {
                            navigate("/dashboard/admin/questionlist");
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    const courseselectchange = async (e) => {
        setSubjects(subjects => [])

        const fetchdataa = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/SubjectManager/GetListByCourse";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss,
                    params: {
                        id: e.target.value
                    }
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                if (res.data['data'][i]['isActive']) {
                                    setSubjects(subjects => [...subjects, res.data['data'][i]])
                                }
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdataa();
    }
    const subjectlist = async () => {
        setLoadingSubjects(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/SubjectManager/GetList";
            await axios({
                method: 'GET',
                url: url,
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            if (res.data['data'][i]['isDeleted'] === false) {
                                if (res.data['data'][i]['isActive']) {
                                    subjects.push({
                                        "id": res.data['data'][i]['id'],
                                        "subjectName": res.data['data'][i]['subjectName'],
                                        "subjectDesc": res.data['data'][i]['subjectDesc'],
                                        "sortId": res.data['data'][i]['sortId']
                                    })
                                }
                            }
                        }
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
        setLoadingSubjects(false);
    }
    const courseList = async () => {
        setLoadingCourses(true);

        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/CourseManager/GetCourses";

            await axios({
                method: 'GET',
                url: url,
                headers: headerss
            }).then(res => {

                if (res.status === 200) {
                    for (let i = 0; i < res.data['data'].length; i++) {
                        if (res.data['data'][i]['isDeleted'] === false) {
                            if (res.data['data'][i]['isActive']) {
                                courses.push({
                                    "id": res.data['data'][i]['id'],
                                    "courseName": res.data['data'][i]['courseName'],
                                    "courseDesc": res.data['data'][i]['courseDesc']
                                })
                            }
                        }
                    }
                    if (res.data['success']) {

                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
        setLoadingCourses(false);
    }
    const taglist = async () => {
        setLoadingTags(true);

        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/TagManager/GetTags";

            await axios({
                method: 'GET',
                url: url,
                headers: headerss
            }).then(res => {

                if (res.status === 200) {
                    for (let i = 0; i < res.data['data'].length; i++) {
                        if (res.data['data'][i]['isDeleted'] === false) {
                            tags.push({
                                "id": res.data['data'][i]['id'],
                                "tagName": res.data['data'][i]['tagName'],
                            })
                        }

                    }
                    if (res.data['success']) {

                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
        setLoadingTags(false);
    }
    const getQuestion = async () => {
        setLoadingQuestion(true)
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/admin/ContentManager/GetById";
            await axios({
                method: 'GET',
                url: url,
                headers: headerss,
                params: {
                    id: params["id"]
                }
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        setData(res.data['data'])
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
        setLoadingQuestion(false);
    }
    useEffect(() => {
        getQuestion();
        taglist();
        courseList();
        subjectlist();
    }, [])


    return (
        <div>
            <br />
            <button className="btn btn-warning" onClick={onClick}>Soruyu Sil</button> <br /><br />
            <button className="btn btn-info" onClick={createAgain}>Soruyu Baştan Oluştur</button> <i>Soruda komplike değişiklik yapmak istediğinizde sistemsel karışıklık olmaması için bu şekilde güncelleme yapınız.</i> <br /><br />
            <h6>{msg}</h6>
            <hr />
            <h4>Temel Düzenleme</h4>
            <form onSubmit={handleSubmit} >

                {loadingCourses && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loadingCourses && (
                    <div class="mb-3">
                        <label for="courseId" class="form-label">{t("selectCourse")}</label>
                        <select id="courseId" class="form-select" onChange={courseselectchange}>
                            <option key="" >{t("selectCourse")}</option>

                            {courses ? (
                                courses && courses.map((dt) => {
                                    return (
                                        <option key={dt.id} value={dt.id}>{dt.courseName} -- {dt.courseDesc}</option>
                                    );
                                })
                            ) : (
                                <p>{t("reloadWarning")}</p>
                            )
                            }
                        </select>
                    </div>
                )}
                {loadingTags && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loadingTags && (
                    <div class="mb-3">
                        <label for="tagId" class="form-label">{t("selecttag")}</label>
                        <select id="tagId" class="form-select" aria-label="Default select example">
                            {tags ? (
                                tags && tags.map((dt) => {
                                    if (dt.id === data.tagId) {
                                        return (
                                            <option value={dt.id} selected>{dt.tagName}</option>
                                        );
                                    }
                                    else {
                                        return (
                                            <option value={dt.id}>{dt.tagName}</option>
                                        );
                                    }
                                })
                            ) : (
                                <p>{t("reloadWarning")}</p>
                            )
                            }
                        </select>
                    </div>
                )}
                {loadingSubjects && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loadingSubjects && (
                    <div class="mb-3">
                        <label for="subjectId" class="form-label">{t("selectSubject")}</label>
                        <select id="subjectId" class="form-select">
                            <option key="" >{t("selectSubject")}</option>

                            {subjects ? (
                                subjects && subjects.map((dt) => {


                                    if (dt.id === data.subjectId) {
                                        return (
                                            <option value={dt.id} selected>{dt.subjectName}  // {dt.subjectDesc}</option>
                                        );
                                    }
                                    else {
                                        return (
                                            <option value={dt.id}>{dt.subjectName}  // {dt.subjectDesc}</option>
                                        );
                                    }
                                })
                            ) : (
                                <p>{t("reloadWarning")}</p>
                            )
                            }
                        </select>
                    </div>

                )}
                {loadingQuestion && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loadingQuestion && (
                    <div>
                        {
                            data.contentType === "choice" ? (
                                <h1>choice sorusu</h1>

                            ) : null
                        }
                        {data.contentType === "match" ? (
                            <h1>unscramble sorusu</h1>
                        ) : null}
                        {data.contentType === "realmatch" ? (
                            <h1>match sorusu</h1>
                        ) : null}
                        {data.contentType === "sentence" ? (
                            <h1>cümle sorusu</h1>
                        ) : null}
                        {data.contentType === "truefalse" ? (
                            <h1>truefalse sorusu</h1>
                        ) : null}
                        {data.contentType === "video" ? (
                            <h1>video content</h1>
                        ) : null}
                        {data.contentType === "write" ? (
                            <h1>write content</h1>
                        ) : null}

                    </div>
                )}

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("update")}</button>
                </div>
            </form>
        </div>
    );
};
export default AdminEditQuestion;

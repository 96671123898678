import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../context/AuthContext';
import axios from "axios";
import Swal from 'sweetalert2'
const PaymentRequest = (props) => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    const [banksdata, setbanksdata] = useState([]);
    const [packages, setPackages] = useState([]);
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingg, setLoadingg] = useState(true);

    const handlePaymentStudent = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/user/Subscription/Apply";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    gatewayId: document.getElementById("studentbankId").value,
                    package: document.getElementById("studentpackageId").value,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Ödeme Talebiniz Başarıyla Alınmıştır!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }

    const handlePaymentManager = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/manager/Subscription/Apply";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    gatewayId: document.getElementById("managerbankId").value,
                    package: document.getElementById("managerpackageId").value,
                },
                headers: headerss
            }).then(res => {
                console.log(res.data);

                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Ödeme Talebiniz Başarıyla Alınmıştır!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            confirmButtonText: `Tamam`
                        });
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }

    const handlePaymentStudentCoupen = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/user/Subscription/ApplyCupon";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    packageId: document.getElementById("coupenpackageId").value,
                    cuponCode: document.getElementById("coupencode").value,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Ödeme Talebiniz Başarıyla Alınmıştır!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            confirmButtonText: `Tamam`
                        });
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    const getBanks = async () => {
        setLoading(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/User/GetGateways";
            await axios({
                method: 'GET',
                url: url,
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            let a = "";
                            if (res.data['data'][i]['isActive']) {
                                banksdata.push({
                                    "id": res.data['data'][i]['id'],
                                    "gatewayName": res.data['data'][i]['gatewayName'],
                                    "typeName": res.data['data'][i]['typeName'],
                                    "typeDescription": res.data['data'][i]['typeDescription'],
                                })
                            }
                        }
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
        setLoading(false);
    }
    const fetchDataPackages = async () => {
        setLoadingg(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/User/GetPackages";
            await axios({
                method: 'GET',
                url: url,
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            let a = user.role;
                            if (a === "Manager") {
                                if (res.data['data'][i]['isActive']) {
                                    if (!res.data['data'][i]['isInvidual']) {
                                        packages.push({
                                            "id": res.data['data'][i]['id'],
                                            "packageName": res.data['data'][i]['packageName'],
                                            "packageDetail": res.data['data'][i]['packageDetail'],
                                            "packagePrice": res.data['data'][i]['packagePrice'],
                                            "howManyDays": res.data['data'][i]['howManyDays'],
                                        })
                                    }
                                }
                            }
                            else if (a === "Student") {
                                if (res.data['data'][i]['isActive']) {
                                    if (res.data['data'][i]['isInvidual']) {
                                        packages.push({
                                            "id": res.data['data'][i]['id'],
                                            "packageName": res.data['data'][i]['packageName'],
                                            "packageDetail": res.data['data'][i]['packageDetail'],
                                            "packagePrice": res.data['data'][i]['packagePrice'],
                                            "howManyDays": res.data['data'][i]['howManyDays'],
                                        })
                                    }
                                }
                            }

                        }
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
        setLoadingg(false);
    }
    useEffect(() => {
        getBanks();
        fetchDataPackages();
    }, []);
    return (
        <div style={{ textAlign: "center", backgroundColor: "white", border: "1px solid black", padding: "1%", margin: "3%" }}>
            <h4>{t('paymentRequest')}</h4>
            <br />
            <div className="row">
                <div className="col-md-6" style={{ border: "1px solid gray", padding: "3%" }}>
                    <h5>{t('usingpaymentmethod')}</h5>
                    <br />
                    {
                        (() => {
                            if (user.user.roleName === "Student") {
                                return (
                                    <form onSubmit={handlePaymentStudent} >
                                        <h6>{msg}</h6>
                                        <div class="mb-3">
                                            <label for="studentbankId" class="form-label">{t('selectBank')}</label>
                                            <select id="studentbankId" class="form-select" aria-label="Default select example">
                                                <option key="" value="">{t('selectBank')}</option>
                                                {banksdata && banksdata.map((dt) => {
                                                    return (
                                                        <option key={dt.id} value={dt.id}>{dt.gatewayName} & {dt.typeName}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div class="mb-3">
                                            <label for="studentpackageId" class="form-label">{t('selectPackage')}</label>
                                            <select id="studentpackageId" class="form-select" aria-label="Default select example">
                                                <option key="" value="">{t('selectPackage')}</option>
                                                {packages && packages.map((dt) => {
                                                    return (
                                                        <option key={dt.id} value={dt.id}>{dt.packageName}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>


                                        <div className='d-flex justify-content-center'>
                                            <button type="submit" class="btn btn-primary btn-lg">{t('create')}</button>
                                        </div>
                                        <br />
                                    </form>
                                );
                            }
                        })()
                    }

                    {
                        (() => {
                            if (user.user.roleName === "Manager") {
                                return (
                                    <form onSubmit={handlePaymentManager} >
                                        <div class="mb-3">
                                            <label for="managerbankId" class="form-label">{t('selectBank')}</label>
                                            <select id="managerbankId" class="form-select" aria-label="Default select example">
                                                <option key="" value="">{t('selectBank')}</option>
                                                {banksdata && banksdata.map((dt) => {
                                                    return (
                                                        <option key={dt.id} value={dt.id}>{dt.gatewayName} & {dt.typeName}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div class="mb-3">
                                            <label for="managerpackageId" class="form-label">{t('selectSection')}</label>
                                            <select id="managerpackageId" class="form-select" aria-label="Default select example">
                                                <option key="" value="">{t('selectSection')}</option>
                                                {packages && packages.map((dt) => {
                                                    return (
                                                        <option key={dt.id} value={dt.id}>{dt.packageName}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <span>Paket seçiniz yada size özel iletilen paket id yi aşağıya yazıp kaydı tamamlayınız.</span>
                                        <div class="mb-3">
                                            <label for="managerpackageId" class="form-label">{t("packageId")}</label>
                                            <textarea class="form-control" id="managerpackageId" />
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <button type="submit" class="btn btn-primary btn-lg">{t('create')}</button>
                                        </div>
                                        <br />
                                    </form>
                                );
                            }
                        })()
                    }
                </div>
                <div className="col-md-6" style={{ border: "1px solid gray", padding: "3%" }}>
                    <h5>{t('usingcoupencode')}</h5>
                    <br />
                    {
                        (() => {
                            if (user.user.roleName === "Student") {
                                return (
                                    <form onSubmit={handlePaymentStudentCoupen} >
                                        <div class="mb-3">
                                            <label for="coupenpackageId" class="form-label">{t('selectPackage')}</label>
                                            <select id="coupenpackageId" class="form-select" aria-label="Default select example">
                                                <option key="" value="">{t('selectPackage')}</option>
                                                {packages && packages.map((dt) => {
                                                    return (
                                                        <option key={dt.id} value={dt.id}>{dt.packageName}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>

                                        <div class="mb-3">
                                            <label for="coupencode" class="form-label">{t('coupencode')}</label>
                                            <textarea class="form-control" id="coupencode" required />
                                        </div>

                                        <div className='d-flex justify-content-center'>
                                            <button type="submit" class="btn btn-primary btn-lg">{t('create')}</button>
                                        </div>
                                        <br />
                                    </form>
                                );
                            }
                        })()
                    }

                </div>
            </div>
            <br />


        </div>
    );
};

export default PaymentRequest;

import { Chart } from "react-google-charts";
import React, { useState, useEffect } from "react";
import { VictoryPie, VictoryLabel } from 'victory';
import { BrowserView, MobileView } from 'react-device-detect';
import { useAuth } from '../../../../context/AuthContext';
import axios from "axios";
import { useTranslation } from "react-i18next";







function UserGeneral() {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [listening, setListening] = useState(0)
    const [reading, setReading] = useState(0)
    const [writing, setWriting] = useState(0)
    const [speaking, setSpeaking] = useState(0)
    const [readingratio, setReadingratio] = useState(0)
    const [writingratio, setWritingratio] = useState(0)
    const [listeningratio, setListeningratio] = useState(0)
    const [speakingratio, setSpeakingratio] = useState(0)
    const [loading, setLoading] = useState(true);

    const fetchdata = async () => {
        setLoading(true);
        let list = 0
        let listtrue = 0
        let read = 0
        let readtrue = 0
        let write = 0
        let writetrue = 0
        let speak = 0
        let speaktrue = 0
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/user/Opt/MyReport";
            await axios({
                method: 'GET',
                url: url,
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    console.log(res.data['data'])
                    for (let i = 0; i < res.data['data'].length; i++) {

                        if (res.data['data'][i]['tagId'] === 1) {
                            list += 1
                            if (res.data['data'][i]['isTrue']) {
                                listtrue += 1
                            }
                        }
                        if (res.data['data'][i]['tagId'] === 2) {
                            write += 1
                            if (res.data['data'][i]['isTrue']) {
                                writetrue += 1
                            }
                        }
                        if (res.data['data'][i]['tagId'] === 4) {
                            read += 1
                            if (res.data['data'][i]['isTrue']) {
                                readtrue += 1

                            }
                        }
                        if (res.data['data'][i]['tagId'] === 5) {
                            speak += 1
                            if (res.data['data'][i]['isTrue']) {
                                speaktrue += 1
                            }
                        }
                    }

                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err);
        }

        setReading(read)
        setWriting(write)
        setSpeaking(speak)
        setListening(list)
        let readratio = parseInt((Number(readtrue) / Number(read)) * 100);
        if (readratio === 0) {
            readratio = 0.1;
        }
        setReadingratio(readratio)
        let listenratio = parseInt((Number(listtrue) / Number(list)) * 100)
        if (listenratio === 0) {
            listenratio = 0.1;
        }
        setListeningratio(listenratio)
        let speakratio = parseInt((Number(speaktrue) / Number(speak)) * 100)
        if (speakratio === 0) {
            speakratio = 0.1;
        }
        setSpeakingratio(speakratio)
        let writeratio = parseInt((Number(writetrue) / Number(write)) * 100)
        if (writeratio === 0) {
            writeratio = 0.1;
        }
        setWritingratio(writeratio)

        setLoading(false);
    }
    useEffect(() => {
        fetchdata()
    }, [])

    return (
        <div className="row">
            <div className="col">
                <h6>{msg}</h6>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <MobileView>
                        <div class="row" style={{ margin: "1%" }}>
                            <div style={{ padding: "2%" }}>
                                <div className="row">
                                    <div class="card" >

                                        <h5 class="card-header" style={{ backgroundColor: "white" }}>
                                            {t('skills')}
                                        </h5>
                                        <div className="card-body">
                                            <br />
                                            <div className="row">
                                                <div style={{ textAlign: "center" }}>
                                                    <h6>Reading / Okuma
                                                    </h6>
                                                </div>

                                                <div >
                                                    <div class="progress" role="progressbar" aria-label="Success example" style={{ height: "100%" }} aria-valuemin="0" aria-valuemax="100">
                                                        <div class="progress-bar bg-success" style={{ width: readingratio + "%" }}><b style={{ color: "black" }}> {readingratio} % </b></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div style={{ textAlign: "center" }}>
                                                    <h6>Writing / Yazma
                                                    </h6>
                                                </div>
                                                <div >
                                                    <div class="progress" role="progressbar" aria-label="Success example" style={{ height: "100%" }} aria-valuemin="0" aria-valuemax="100">
                                                        <div class="progress-bar bg-success" style={{ width: writingratio + "%" }}>  <b style={{ color: "black" }}> {writingratio} % </b> </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div style={{ textAlign: "center" }}>
                                                    <h6>Listening / Dinleme
                                                    </h6>
                                                </div>
                                                <div>
                                                    <div class="progress" role="progressbar" aria-label="Success example" style={{ height: "100%" }} aria-valuemin="0" aria-valuemax="100">
                                                        <div class="progress-bar bg-warning" style={{ width: listeningratio + "%" }}><b style={{ color: "black" }}> {listeningratio} % </b></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div style={{ textAlign: "center" }}>
                                                    <h6>Speaking / Konuşma
                                                    </h6>
                                                </div>
                                                <div >
                                                    <div class="progress" role="progressbar" aria-label="Success example" style={{ height: "100%" }} aria-valuemin="0" aria-valuemax="100">
                                                        <div class="progress-bar bg-info" style={{ width: speakingratio + "%" }}><b style={{ color: "black" }}> {speakingratio} % </b></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer" style={{ backgroundColor: "white" }}>

                                            <div className="row">
                                                <a href="/dashboard/userskill" class="btn btn-primary">{t('showdetails')}</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: "2%" }}>
                                <div className="row">
                                    <div class="card">
                                        <h5 class="card-header" style={{ backgroundColor: "white" }}>
                                            {t('generalprogress')}

                                        </h5>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-8" style={{ float: "left" }}>
                                                    <VictoryPie
                                                        data={[
                                                            { x: " ", y: 2 },
                                                            { x: " ", y: 5 },
                                                            { x: " ", y: 8 },
                                                            { x: " ", y: 15 },
                                                            { x: " ", y: 300 },
                                                        ]}
                                                        width={570}
                                                        innerRadius={120}
                                                        colorScale={["red", "orange", "blue", "green", "Gainsboro"]}
                                                    />

                                                </div>
                                                <div className="col-md-4">
                                                    <span style={{ fontSize: 30, color: "red" }}>
                                                        &#8226;

                                                        Starter
                                                    </span>
                                                    <span style={{ fontSize: 30, color: "orange" }}>
                                                        &#8226;

                                                        Average
                                                    </span>
                                                    <span style={{ fontSize: 30, color: "blue" }}>
                                                        &#8226;

                                                        Advancing
                                                    </span>
                                                    <span style={{ fontSize: 30, color: "green" }}>
                                                        &#8226;

                                                        Mastering
                                                    </span>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="card-footer" style={{ backgroundColor: "white" }}>
                                            <div className="row">
                                                <a href="/dashboard/userprogress" class="btn btn-primary">{t('showdetails')}</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </MobileView>
                )}
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <BrowserView>

                        <div class="row">
                            <div style={{ padding: "2%" }} className="col-md">
                                <div className="row">
                                    <div class="card" style={{ height: "400px" }}>

                                        <h4 class="card-header" style={{ backgroundColor: "white", textAlign: "center" }}>
                                            {t('skills')}
                                        </h4>
                                        <div className="card-body">
                                            <div className="row" style={{ marginTop: "5%", height: "36px" }} >
                                                <div className="col-md-3" style={{ textAlign: "center" }}>
                                                    <h4 style={{ textAlign: "left" }}>
                                                        Reading / Okuma
                                                    </h4>
                                                </div>
                                                <div className="col-md-9" >
                                                    <div class="progress" style={{ fontSize: "25px", height: "30px" }} role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                                        <div class="progress-bar bg-success" style={{ width: readingratio + "%" }}><b style={{ color: "black" }}> {readingratio} % </b></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: "5%", height: "36px" }}>
                                                <div className="col-md-3" style={{ textAlign: "center" }}>
                                                    <h4 style={{ textAlign: "left" }}>Writing / Yazma
                                                    </h4>
                                                </div>
                                                <div className="col-md-9">
                                                    <div class="progress" style={{ fontSize: "25px", height: "30px" }} role="progressbar">
                                                        <div class="progress-bar bg-success" style={{ width: writingratio + "%" }}> <b style={{ color: "black" }}> {writingratio} % </b></div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row" style={{ marginTop: "5%", height: "36px" }}>
                                                <div className="col-md-3" style={{ textAlign: "center" }}>
                                                    <h4 style={{ textAlign: "left" }}>Listening / Dinleme
                                                    </h4>
                                                </div>
                                                <div className="col-md-9">
                                                    <div class="progress" style={{ fontSize: "25px", height: "30px" }} role="progressbar" aria-label="Success example" >
                                                        <div class="progress-bar bg-warning" style={{ width: listeningratio + "%" }}> <b style={{ color: "black" }}> {listeningratio} % </b></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row" style={{ marginTop: "5%", height: "36px" }}>
                                                <div className="col-md-3" style={{ textAlign: "center" }}>
                                                    <h4 style={{ textAlign: "left" }}>Speaking / Konuşma
                                                    </h4>
                                                </div>
                                                <div className="col-md-9">
                                                    <div class="progress" style={{ fontSize: "25px", height: "30px" }} role="progressbar" aria-label="Success example">
                                                        <div class="progress-bar bg-info" style={{ width: speakingratio + "%" }}> <b style={{ color: "black" }}> {speakingratio} % </b></div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="card-footer" style={{ backgroundColor: "white" }}>
                                            <div className="row">
                                                <div className="col-8"></div>
                                                <div className="col-4" style={{ textAlign: "right" }}>

                                                    <a href="/dashboard/userskill" style={{ fontSize: "15px" }} class="btn btn-primary">{t('showdetails')}</a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: "2%" }} className="col-md">
                                <div className="row">
                                    <div class="card" style={{ height: "400px" }}>
                                        <h4 class="card-header" style={{ backgroundColor: "white", textAlign: "center" }}>
                                            {t('progress')}
                                        </h4>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-8">
                                                    <svg width={300} height={250}>
                                                        <VictoryPie
                                                            standalone={false}
                                                            width={300}
                                                            height={300}
                                                            innerRadius={80}
                                                            data={[{ x: " ", y: 2 },
                                                            { x: " ", y: 5 },
                                                            { x: " ", y: 8 },
                                                            { x: " ", y: 15 },
                                                            { x: " ", y: 300 },]}
                                                            colorScale={["red", "orange", "blue", "green", "Gainsboro"]}
                                                            labels={() => null}
                                                        />
                                                        <VictoryLabel
                                                            textAnchor="middle"
                                                            verticalAnchor="middle"
                                                            style={[
                                                                {
                                                                    fontSize: 42,
                                                                    color: "#28323B",
                                                                    fontWeight: "550",
                                                                    lineHeight: 30
                                                                },
                                                                { fontWeight: "550", fontSize: 16 }
                                                            ]}
                                                            x={300 * 0.5}
                                                            y={300 * 0.525}
                                                            text={["%12", "General Progress"]}
                                                        />
                                                    </svg>
                                                </div>
                                                <div className="col-4">
                                                    <span style={{ fontSize: 28, color: "red", marginTop: "25%" }}>
                                                        &#8226;

                                                        Starter
                                                    </span>
                                                    <span style={{ fontSize: 28, color: "orange", marginTop: "5%" }}>
                                                        &#8226;

                                                        Average
                                                    </span>
                                                    <span style={{ fontSize: 28, color: "blue", marginTop: "5%" }}>
                                                        &#8226;

                                                        Advancing
                                                    </span>
                                                    <span style={{ fontSize: 28, color: "green", marginTop: "5%" }}>
                                                        &#8226;

                                                        Mastering
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer" style={{ backgroundColor: "white" }}>
                                            <div className="row">
                                                <div className="col-8"></div>
                                                <div className="col-4" style={{ textAlign: "right" }}>

                                                    <a href="/dashboard/userprogress" style={{ fontSize: "15px" }} class="btn btn-primary">{t('showdetails')}</a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </BrowserView>
                )}
            </div >
        </div >
    );
}
export default UserGeneral;
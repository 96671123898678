import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../../../context/AuthContext';
import Swal from 'sweetalert2'
import GeneralRequestHelper from '../../../../../Helpers/GeneralRequestHelper';

export const AdminBankEdit = () => {
    const params = useParams();
    const [data, setData] = useState([]);
    const { t } = useTranslation()
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [loading, setLoading] = useState(true);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const dataa = {
                id: data.id,
                isDeleted: false,
                gatewayName: document.getElementById("gatewayName").value,
                typeName: document.getElementById("paymentmethod").value,
                typeDescription: document.getElementById("ibanNreceiver").value,
                sortId: document.getElementById("sortId").value,
                isActive: document.getElementById("isActive").value
            };
            await GeneralRequestHelper("/api/admin/Gateway/Update", "put", dataa, user.token).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            window.location.reload();
                        })
                    }
                    else {
                        setMsg(res.data['message']);
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            timer: 3000,
                            confirmButtonText: `Tamam`
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }



    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                let dataa = {
                    id: params["id"]
                };
                await GeneralRequestHelper("/api/admin/Gateway/Get", "get", dataa, user.token).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            setData(res.data['data']);
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            } catch (err) {
                setMsg(err.message);
            }
            setLoading(false);

        }
        fetchData();
    }, [])

    return (
        <div style={{ backgroundColor: "white", padding: "3%" }}>
            <div style={{ textAlign: "center" }}>
                <h1>{t("editpaymentmethod")}</h1>
            </div>
            <h5>{msg}</h5>
            {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
            {!loading && (
                <form onSubmit={handleSubmit} style={{ border: "1px solid black", padding: "2%" }}>
                    <div class="mb-3">
                        <label for="paymentmethod" class="form-label">{t("paymentmethod")}</label>
                        <textarea class="form-control" name="paymentmethod" id="paymentmethod" defaultValue={data.typeName} />
                    </div>
                    <div class="mb-3">
                        <label for="gatewayName" class="form-label">{t("bankName")}</label>
                        <textarea class="form-control" id="gatewayName" defaultValue={data.gatewayName} />
                    </div>
                    <div class="mb-3">
                        <label for="ibanNreceiver" class="form-label">{t("ibanNreceiver")}</label>
                        <textarea class="form-control" id="ibanNreceiver" defaultValue={data.typeDescription} />
                    </div>
                    <div class="mb-3">
                        <label for="sortId" class="form-label">{t("sortid")}</label> <i>Dikkat!! Listenen kontrol ediniz.</i>
                        <textarea class="form-control" name="sortId" id="sortId" defaultValue={data.sortId} />
                    </div>

                    <div class="mb-3">
                        <label for="isActive" class="form-label">{t('isActive')}</label>

                        {data.isActive ? (
                            <select id="isActive" class="form-select" required >
                                <option key="1" value="true" selected>{t('active')}</option>
                                <option key="0" value="false" >{t('passive')}</option>
                            </select>

                        ) : (
                            <select id="isActive" class="form-select" required >
                                <option key="0" value="false" selected>{t('passive')}</option>
                                <option key="1" value="true" >{t('active')}</option>
                            </select>
                        )}
                    </div>

                    <div className='d-flex justify-content-center'>
                        <button type="submit" class="btn btn-primary btn-lg">{t("update")}</button>
                    </div>

                </form>
            )}

        </div>
    );
};
export default AdminBankEdit;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

const Pagination = (props) => {
    const params = useParams();

    const handleClick = event => {

        window.location.href = event.target.id

    };
    useEffect(() => {
        var intvalue = (Math.floor(props.pagination.length / 10)) + 1;
    }, []);
    return (
        <div class="justify-content-center" style={{ alignContent: "center", alignItems: "center", justifyItems: "center" }}>
            <div>
                {props.pagination && props.pagination.map((dt) => {

                    if (dt.isActive) {
                        if (dt.answered) {
                            if (dt.answerCorrect) {
                                return (
                                    <button id={dt.href} onClick={handleClick} style={{ backgroundColor: "green" }} class="btn page-item active" aria-current="page">
                                        <a id={dt.href} onClick={handleClick} style={{ backgroundColor: "green", fontSize: "18px", color: "white" }} class="page-link" >{dt.id}</a>
                                    </button>
                                )
                            }
                            return (
                                <button id={dt.href} onClick={handleClick} style={{ backgroundColor: "red" }} class="btn page-item active" aria-current="page">
                                    <a id={dt.href} onClick={handleClick} style={{ backgroundColor: "red", fontSize: "18px", color: "white" }} class="page-link">{dt.id}</a>
                                </button>
                            )
                        }
                        else {
                            return (
                                <button id={dt.href} onClick={handleClick} style={{ backgroundColor: "black" }} class="btn page-item active" aria-current="page">
                                    <a id={dt.href} onClick={handleClick} style={{ backgroundColor: "black", fontSize: "18px", color: "white" }} class="page-link">{dt.id}</a>
                                </button>
                            )
                        }
                    }
                    else {
                        if (dt.answered) {
                            if (dt.answerCorrect) {
                                return (
                                    <button id={dt.href} onClick={handleClick} style={{ backgroundColor: "green" }} class="btn page-item active" aria-current="page">
                                        <a id={dt.href} onClick={handleClick} style={{ backgroundColor: "green", fontSize: "12px", color: "white" }} class="page-link" >{dt.id}</a>
                                    </button>
                                )
                            }
                            return (
                                <button id={dt.href} onClick={handleClick} style={{ backgroundColor: "red" }} class="btn page-item active" aria-current="page">
                                    <a id={dt.href} onClick={handleClick} style={{ backgroundColor: "red", fontSize: "12px", color: "white" }} class="page-link" >{dt.id}</a>
                                </button>
                            )
                        }

                        else {
                            return (
                                <button id={dt.href} onClick={handleClick} style={{ backgroundColor: "gray" }} class="btn page-item active" aria-current="page">
                                    <a id={dt.href} onClick={handleClick} style={{ backgroundColor: "gray", fontSize: "12px", color: "white" }} class="page-link">{dt.id}</a>
                                </button>
                            )
                        }
                    }
                })}


            </div>


        </div>
    );
};

export default Pagination;

// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";

// const Pagination = (props) => {
//     const params = useParams();
//     const [pageNumber, setpageNumber] = useState([]);

//     useEffect(() => {
//         var intvalue = (Math.floor(props.pagination.length / 10)) + 1;
//         setpageNumber(intvalue)
//     }, []);
//     return (
//         <div style={{ alignContent: "center", alignItems: "center", justifyItems: "center" }} >
//             {Array(pageNumber).fill(1).map((el, i) =>
//                 <div style={{ alignContent: "center", alignItems: "center", justifyItems: "center" }}>
//                     {props.pagination && props.pagination.map((dt) => {
//                         if (Number(10 * i) < dt.id) {
//                             if (Number(10 * (i + 1)) >= dt.id) {
//                                 if (dt.isActive) {
//                                     if (dt.answered) {
//                                         if (dt.answerCorrect) {
//                                             return (
//                                                 <button style={{ backgroundColor: "green" }} class="btn page-item active" aria-current="page">
//                                                     <a style={{ backgroundColor: "green", fontSize: "18px" }} class="page-link" href={dt.href}>{dt.id}</a>
//                                                 </button>
//                                             )
//                                         }
//                                         return (
//                                             <button style={{ backgroundColor: "red" }} class="btn page-item active" aria-current="page">
//                                                 <a style={{ backgroundColor: "red", fontSize: "18px" }} class="page-link" href={dt.href}>{dt.id}</a>
//                                             </button>
//                                         )
//                                     }
//                                     else {
//                                         return (
//                                             <button style={{ backgroundColor: "white" }} class="btn page-item active" aria-current="page">
//                                                 <a style={{ backgroundColor: "black", fontSize: "18px" }} class="page-link" href={dt.href}>{dt.id}</a>
//                                             </button>
//                                         )
//                                     }
//                                 }
//                                 else {
//                                     if (dt.answered) {
//                                         if (dt.answerCorrect) {
//                                             return (
//                                                 <button style={{ backgroundColor: "green" }} class="btn page-item active" aria-current="page">
//                                                     <a style={{ backgroundColor: "green", fontSize: "12px" }} class="page-link" href={dt.href}>{dt.id}</a>
//                                                 </button>
//                                             )
//                                         }
//                                         return (
//                                             <button style={{ backgroundColor: "red" }} class="btn page-item active" aria-current="page">
//                                                 <a style={{ backgroundColor: "red", fontSize: "12px" }} class="page-link" href={dt.href}>{dt.id}</a>
//                                             </button>
//                                         )
//                                     }

//                                     else {
//                                         return (
//                                             <button style={{ backgroundColor: "gray" }} class="btn page-item active" aria-current="page">
//                                                 <a style={{ backgroundColor: "gray", fontSize: "12px" }} class="page-link" href={dt.href}>{dt.id}</a>
//                                             </button>
//                                         )
//                                     }
//                                 }
//                             }
//                         }
//                     })}


//                 </div>
//             )}


//         </div>
//     );
// };

// export default Pagination;


// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import ResponsivePagination from 'react-responsive-pagination';
// import 'react-responsive-pagination/themes/classic.css';
// const Pagination = (props) => {
//     const params = useParams();
//     const [currentPage, setCurrentPage] = useState(0);
//     const [totalPages, setTotalPages] = useState(0);
//     function changePage(e) {
//         window.location.href = "/dashboard/user/course/question/1/" + e;
//     }
//     useEffect(() => {
//         setTotalPages(props.pagination.length)
//         for (let i = 0; i < props.pagination.length; i++) {
//             if (props.pagination[i]['isActive']) {
//                 setCurrentPage(i + 1)
//             }
//         }
//         console.log(props.pagination)
//     }, []);

//     return (
//         <ResponsivePagination

//             current={currentPage}
//             total={totalPages}
//             onPageChange={changePage}
//         />
//     );
// };

// export default Pagination;




import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAuth } from '../../../../../../../context/AuthContext';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import GeneralRequestHelper from '../../../../../../../Helpers/GeneralRequestHelper';
import HandleVideoChange from '../Components/HandleVideoChange';
import Tag from '../UI/Tag';
import TitleArea from '../UI/TitleArea';

const VideoContent = (props) => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    const params = useParams()
    const navigate = useNavigate();
    const [msg, setMsg] = useState([]);
    const [videoPath, setVideoPath] = useState(null);

    const handletype1 = async (e) => {
        e.preventDefault();
        try {
            var data = {
                isDeleted: false,
                tagId: document.getElementById("tagId").value,
                sortId: params["sort"],
                contentType: "video",
                subjectId: params["subject"],
                contentStr: videoPath,
                detail: "",
                notfyText: "",
                answer: ""
            }
            await GeneralRequestHelper("/api/admin/ContentManager/Create", "post", data, user.token).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            navigate("/dashboard/admin/createquestion");
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err.message);
        }
    }
    const handleChange = async (event) => {
        if (event.target.type === "textarea") {
            setVideoPath(event.target.value)
        }
        else if (event.target.type === "file") {

            const formData = new FormData();
            formData.append('FormFile', event.target.files[0]);
            try {
                await HandleVideoChange(formData, user.token, setVideoPath, setMsg).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            setVideoPath(res.data['data']['path'])
                            alert("Yüklendi");
                        }
                        else {
                            alert("hata")
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            } catch (err) {
                setMsg(err.message);
            }
        }
    }




    useEffect(() => {
    });

    return (
        <div>
            <TitleArea msg={msg} text={"Video Ders Oluşturma Sayfası"} />
            <Tag />
            <br />
            <form onSubmit={handletype1}>
                <div class="mb-3">
                    <label for="videocontent" class="form-label">{t("addVideo")}</label>
                    <input class="form-control" type="file" accept='video' onChange={handleChange} id="videocontent" name="videocontent" required />
                </div>
                <div class="mb-3">
                    <label for="addvideos" class="form-label">{t("addVideobyUrl")}</label>
                    <textarea class="form-control" rows={1} type="text" onChange={handleChange} id="addvideos" name="addvideos" />
                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                </div>
            </form>
        </div>
    );
};

export default VideoContent;
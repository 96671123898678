import React from 'react';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../context/AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MobileNavbarAdmin = (props) => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    return (
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
            <div class="container-fluid" style={{ backgroundColor: "white" }}>
                <a className="navbar-brand" href="/">
                    <img src="/images/logo.png" alt="logo" />
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                {t('homepagesettings')}
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/dashboard/admin/homepage">{t('generalsettings')}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/slider">{t('slidersettings')}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/corefeatures">{t('corefeaturessettings')}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/aboutus">{t('aboutussettings')}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/systemusage">{t('systemusage')}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/testimonials">{t('testimonials')}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/footer">{t('footersettings')}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/contactus">{t('Contactussection')}</a></li>

                                Contactus
                                <li><a class="dropdown-item" href="/dashboard/admin/mainpackages">{t('mainpackages')}</a></li>
                            </ul>
                        </li>
                        <hr />
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                {t("reviewsettings")}
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/dashboard/admin/createreview">{t("createreview")}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/reviewlist">{t("reviewlist")}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/getcontacts">{t("getcontacts")}</a></li>
                            </ul>
                        </li>
                        <hr />
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                {t("individualUserSettings")}
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/dashboard/admin/createusertype">Create User Type</a></li>
                                {/* <li><a class="dropdown-item" href="/dashboard/admin/usertypelist">User Types List</a></li> */}
                                <li><a class="dropdown-item" href="/dashboard/admin/userlist">{t('userList')}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/schoollist">  {t('schoolList')}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/createschool"> {t('createSchool')}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/corporateuserlist">  {t('corporateuserList')}</a></li>
                            </ul>
                        </li>
                        <hr />

                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                {t("tickets")}
                            </a>
                            <ul class="dropdown-menu">
                                {/* <li><a class="dropdown-item" href="/dashboard/admin/ticketlist">  {t('ticketlist')}</a></li> */}
                                <li><a class="dropdown-item" href="/dashboard/admin/searchticket"> {t('searchticket')}</a></li>
                                {/* <li><a class="dropdown-item" href="/dashboard/admin/ticketdetail"> {t('ticketdetail')}</a></li> */}
                            </ul>
                        </li>
                        <hr />

                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                {t("corporateUserSettings")}
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/dashboard/admin/schoollist">  {t('schoolList')}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/createschool"> {t('createSchool')}</a></li>
                            </ul>
                        </li>

                        <hr />
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                {t("websiteuser")}
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/dashboard/admin/createmoderator">{t('createmoderator')}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/moderatorlist">{t('moderatorlist')}</a></li>

                            </ul>
                        </li>
                        <hr />
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                {t('financialSettings')}
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/dashboard/admin/bankaccount">{t('bankAccounts')}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/packageprice">{t('packagesAndPrices')}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/packagelist">{t('packagesList')}</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/payment">  {t("paymentRequests&newRegister")}</a></li>
                            </ul>
                        </li>
                        <hr />
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                {t('coupensettings')}
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/dashboard/admin/packagelist">{t('coupenList')}</a></li>
                            </ul>
                        </li>
                        <hr />

                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                {t('courseSettings')}
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/dashboard/admin/createcourse">{t('createCourse')} </a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/courselist"> {t('courseList')}</a></li>
                            </ul>
                        </li>

                        <hr />
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                Subject Settings
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/dashboard/admin/createsubject">Create Subject</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/subjectlist">Subject List</a></li>

                            </ul>
                        </li>

                        <hr />
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >
                                Question Settings
                            </a>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="/dashboard/admin/createquestion">Create Question</a></li>
                                <li><a class="dropdown-item" href="/dashboard/admin/questionlist">Question List</a></li>

                            </ul>
                        </li>

                    </ul>
                    <hr />
                    <form class="d-flex" style={{ padding: "3%" }}>
                        <a href="/profile">
                            <i
                                class="fa fa-user-circle"
                                style={{ fontSize: 30 }}
                                aria-hidden="true"
                            >
                            </i>
                            &nbsp;
                            <span>

                                <strong>{user.name}</strong>
                                <br />
                                {user.role}
                            </span>
                        </a>
                    </form>
                </div>
            </div>
        </nav>
    );
};

export default MobileNavbarAdmin;
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../context/AuthContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from "axios";

const ChangePassword = (props) => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    const [msgPSW, setMsgPSW] = useState([]);

    const change = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/user/Opt/UpdatePassword";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    oldPassword: document.getElementById("currentpassword").value,
                    newPassword: document.getElementById("newpassword").value,
                    newPasswordVerify: document.getElementById("newpasswordcheck").value,
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Şifreniz Başarıyla Değiştirilmiştir.",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                    }
                    else {
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            confirmButtonText: `Tamam`
                        });
                        setMsgPSW(res.data['message']);
                    }
                }
                else {
                    setMsgPSW("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsgPSW(err.message);
        }
    }

    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <h3>{t("changepassword")}</h3>
            </div>
            <form onSubmit={change} style={{ border: "1px solid black", padding: "2%" }}>
                {msgPSW}
                <div class="mb-3">
                    <label for="currentpassword" class="form-label">{t("currentpassword")}</label>
                    <input type="password" class="form-control" id="currentpassword" required />
                </div>

                <div class="mb-3">
                    <label for="newpassword" class="form-label">{t("newpassword")}</label>
                    <input type="password" class="form-control" id="newpassword" required />
                </div>

                <div class="mb-3">
                    <label for="newpasswordcheck" class="form-label">{t("newpassword")}</label>
                    <input type="password" class="form-control" id="newpasswordcheck" required />
                </div>

                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("changepassword")}</button>
                </div>

            </form>
            <br />
        </div>

    );
};

export default ChangePassword;
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import HandleVideoChange from '../Components/HandleVideoChange';
import HandlePhotoChange from '../Components/HandlePhotoChange';
import HandleSoundChange from '../Components/HandleSoundChange';
import { useAuth } from '../../../../../../../context/AuthContext';

const AddContentQuestion = (props) => {
    const { user } = useAuth();

    const { t, i18n } = useTranslation()
    const handlePhotoChange = async (event) => {
        if (event.target.type === "textarea") {
            props.setImagePath(event.target.value)
        }
        else if (event.target.type === "file") {
            const formData = new FormData();
            formData.append('FormFile', event.target.files[0]);
            await HandlePhotoChange(formData, user.token, props.setImagePath, props.setMsg)
        }
    }
    const handleVideoChange = async (event) => {
        if (event.target.type === "textarea") {
            props.setVideoPath(event.target.value)
        }
        else if (event.target.type === "file") {
            const formData = new FormData();
            formData.append('FormFile', event.target.files[0]);
            await HandleVideoChange(formData, user.token, props.setVideoPath, props.setMsg)
        }
    }

    const handleSoundChange = async (event) => {
        if (event.target.type === "textarea") {
            props.setSoundPath(event.target.value)
        }
        else if (event.target.type === "file") {
            const formData = new FormData();
            formData.append('FormFile', event.target.files[0]);
            await HandleSoundChange(formData, user.token, props.setSoundPath, props.setMsg)
        }
    }
    return (


        <div class="accordion accordion-flush" id="accordionFlushExample" style={{ backgroundColor: "gray" }}>
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        <h4>{t('addFile')}</h4>
                    </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample" style={{ backgroundColor: "white" }}>
                    <div className="row" style={{ padding: "1%" }}>
                        <div className="col-md-4">
                            <div class="mb-1">
                                <label for="addphoto" class="form-label">{t("addPhoto")}</label>
                                <input class="form-control" type="file" accept='image/png, image/jpg, image/jpeg' onChange={handlePhotoChange} id="addphoto" name="addphoto" required />
                            </div>
                            <div class="mb-1">
                                <label for="addphotos" class="form-label">{t("addPhotobyUrl")}</label>
                                <textarea class="form-control" rows={1} type="text" onChange={handlePhotoChange} id="addphotos" name="addphotos" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="mb-1">
                                <label for="addvideo" class="form-label">{t("addVideo")}</label>
                                <input class="form-control" type="file" accept='video' onChange={handleVideoChange} id="addvideo" name="addvideo" required />
                            </div>
                            <div class="mb-1">
                                <label for="addvideos" class="form-label">{t("addVideobyUrl")}</label>
                                <textarea class="form-control" rows={1} type="text" onChange={handleVideoChange} id="addvideos" name="addvideos" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="mb-1">
                                <label for="addsound" class="form-label">{t("addSound")}</label>
                                <input class="form-control" type="file" accept=".mp4,.mp3,audio/*" onChange={handleSoundChange} id="addsound" name="addsound" required />
                            </div>
                            <div class="mb-1">
                                <label for="addsounds" class="form-label">{t("addSoundbyUrl")}</label>
                                <textarea class="form-control" rows={1} type="text" onChange={handleSoundChange} id="addsounds" name="addsounds" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <br />

        </div>



    );
};

export default AddContentQuestion;
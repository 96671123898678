import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAuth } from '../../../../../context/AuthContext';
import Swal from 'sweetalert2'


export const AdminPackageandPrice = () => {
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const { t } = useTranslation()

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token

            }
            let url = "https://api.heryerdeingilizce.com/api/admin/Package/Create";
            await axios({
                method: 'Post',
                url: url,
                data: {
                    packageName: document.getElementById("packageName").value,
                    packageDetail: document.getElementById("packageDetail").value,
                    packagePrice: document.getElementById("packagePrice").value,
                    studentLimit: document.getElementById("studentLimit").value,
                    howManyDays: document.getElementById("howManyDays").value,
                    sortNumber: document.getElementById("sortNumber").value,
                    isItPrivate: document.getElementById("isItPrivate").value,
                    isInvidual: document.getElementById("isInvidual").value,
                    isActive: true
                },
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`
                        });
                        window.location.reload();

                    }
                    else {
                        Swal.fire({
                            title: "error!",
                            icon: "error",
                            confirmButtonText: `Tamam`
                        });
                        setMsg(res.data['message']);
                    }
                }
                else {
                    Swal.fire({
                        title: "hata!",
                        icon: "error",
                        confirmButtonText: `Tamam`
                    });
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err);
        }
    }

    return (
        <div style={{ backgroundColor: "white", padding: "3%" }}>
            <div style={{ textAlign: "center" }}>
                <h1>{t("packagesAndPrices")}</h1>
            </div>
            <h4>{msg}</h4>
            <form onSubmit={handleSubmit} >
                <h4>{t("createPackage")}</h4>

                <div class="mb-3">
                    <label for="packageName" class="form-label">{t("packageName")}</label>
                    <textarea class="form-control" id="packageName" required />
                </div>
                <div class="mb-3">
                    <label for="packageDetail" class="form-label">{t("packageDescription")}</label>
                    <textarea class="form-control" id="packageDetail" required />
                </div>
                <div class="mb-3">
                    <label for="packagePrice" class="form-label">{t("packagePrice")}</label> <br />
                    <i>{t('useNumbers')}</i>
                    <textarea class="form-control" id="packagePrice" required />
                </div>

                <div class="mb-3">
                    <label for="studentLimit" class="form-label">{t("studentLimit")}</label><br />
                    <i>{t('useNumbers')}</i>
                    <textarea class="form-control" id="studentLimit" required />
                </div>

                <div class="mb-3">
                    <label for="howManyDays" class="form-label">{t("validityDate")}</label><br />
                    <i>{t('useNumbers')}</i><i>{t('daily')}</i>
                    <textarea class="form-control" id="howManyDays" required />
                </div>
                <div class="mb-3">
                    <label for="isInvidual" class="form-label">{t('isInvidual')}</label>
                    <select id="isInvidual" class="form-select" required >
                        <option key="1" value="test">{t('select')}</option>
                        <option key="1" value="true">{t('yes')}</option>
                        <option key="0" value="false">{t('no')}</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label for="isItPrivate" class="form-label">{t('isItPrivate')}</label>
                    <select id="isItPrivate" class="form-select" required >
                        <option key="1" value="test">{t('select')}</option>
                        <option key="1" value="true">{t('yes')}</option>
                        <option key="0" value="false">{t('no')}</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label for="sortNumber" class="form-label">{t("sortid")}</label>
                    <textarea class="form-control" id="sortNumber" required />
                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("create")}</button>
                </div>

            </form>
        </div>
    )
}
export default AdminPackageandPrice;
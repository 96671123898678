import React, { useState, useEffect } from "react";
import Datatable from "../../../Components/Datatable"
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../../context/AuthContext';
import GeneralRequestHelper from '../../../../../../Helpers/GeneralRequestHelper';

export const AdminCourseList = () => {
    const [data, setData] = useState([]);
    const [deleteddata, setdeletedData] = useState([]);

    const [loading, setLoading] = useState(true);
    const [loadingg, setLoadingg] = useState(true);
    const { t } = useTranslation();
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);




    const columns = React.useMemo(
        () => [
            {
                Header: t('courseName'),
                Footer: t('courseName'),
                accessor: 'courseName',
            },
            {
                Header: t('courseDescription'),
                Footer: t('courseDescription'),
                accessor: 'courseDesc',
            },
            {
                Header: t('sectionName'),
                Footer: t('sectionName'),
                accessor: 'sectionName',
            },
            {
                Header: t('isActive'),
                Footer: t('isActive'),
                accessor: 'isActive',
            },
            {
                Header: t('editCourse'),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-info btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t('edit')}
                        </button>

                    </div>
                ),
                id: 'actionEditCourse',
                Footer: t('editCourse'),
            },

        ],
        [],
    )

    const deletedcolumns = React.useMemo(
        () => [
            {
                Header: t('courseName'),
                Footer: t('courseName'),
                accessor: 'courseName',
            },
            {
                Header: t('courseDescription'),
                Footer: t('courseDescription'),
                accessor: 'courseDesc',
            },
            {
                Header: t('sectionName'),
                Footer: t('sectionName'),
                accessor: 'sectionName',
            },
            {
                Header: t('isActive'),
                Footer: t('isActive'),
                accessor: 'isActive',
            },

            {
                Header: t('editCourse'),
                accessor: (originalRow, rowIndex) => (
                    <div className="d-flex  align-items-center">
                        <button
                            className="btn btn-info btn-sm"
                            onClick={() => handleEdit(originalRow)}
                        >
                            {t('edit')}
                        </button>

                    </div>
                ),
                id: 'actionEditCourse',
                Footer: t('editCourse'),
            },

        ],
        [],
    )


    const handleEdit = (row) => {
        window.location.href = "/dashboard/admin/editcourse/" + row['id'];
    }

    useEffect(() => {
        let sections = []
        const fetchsections = async () => {
            setLoadingg(true);
            try {
                await GeneralRequestHelper("/api/admin/SectionManager/GetList", "get", "", user.token).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                sections.push(res.data['data'][i])
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            } catch (err) {
                setMsg(err.message);
            }
            setLoadingg(false);
        }

        fetchsections();
        const fetchCourses = async () => {
            setLoading(true);
            try {
                await GeneralRequestHelper("/api/admin/CourseManager/GetCourses", "get", "", user.token).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                let activity = "";
                                var sectionName = "";
                                for (let j = 0; j < sections.length; j++) {
                                    if (sections[j]['id'] === res.data['data'][i]['sectionId']) {
                                        sectionName = sections[j]['sectionName'];
                                    }
                                }
                                if (res.data['data'][i]['isActive']) {
                                    activity = t('active');
                                    data.push({
                                        "id": res.data['data'][i]['id'],
                                        "courseName": res.data['data'][i]['courseName'],
                                        "courseDesc": res.data['data'][i]['courseDesc'],
                                        "sortId": res.data['data'][i]['sortId'],
                                        "isActive": activity,
                                        "sectionName": sectionName,
                                    })
                                }
                                else {
                                    activity = t('passive');
                                    deleteddata.push({
                                        "id": res.data['data'][i]['id'],
                                        "courseName": res.data['data'][i]['courseName'],
                                        "courseDesc": res.data['data'][i]['courseDesc'],
                                        "sortId": res.data['data'][i]['sortId'],
                                        "isActive": activity,
                                        "sectionName": sectionName,
                                    })
                                }

                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            } catch (err) {
                setMsg(err.message);
            }
            setLoading(false);
        }
        //fetchdata();
        fetchCourses();


    }, [])



    return (
        <div style={{ backgroundColor: "white", width: "full" }} >
            <div style={{ textAlign: "center" }}>
                <h3>{msg}</h3>
                <h1>{t("courseList")}</h1>
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <div>
                        <div>
                            <Datatable data={data} columns={columns} tableBaslik={t("courseList")} />
                        </div>
                        <br />
                        <hr />  <hr />
                        <br />
                        <div>
                            <Datatable data={deleteddata} columns={deletedcolumns} tableBaslik={t("passivecourseList")} />
                        </div>
                        <br />
                    </div>


                )}
            </div>



        </div>
    );
};
export default AdminCourseList;

import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../../../../context/AuthContext';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import GeneralRequestHelper from '../../../../../../../Helpers/GeneralRequestHelper';
import SubmitButton from '../UI/SubmitButton';
import InfoInput from '../UI/InfoInput';
import CorrectAnswer from '../UI/CorrectAnswer';
import QuestionTextInput from '../UI/QuestionTextInput';
import AddContentQuestion from '../UI/AddContentQuestion';
import Tag from '../UI/Tag';
import TitleArea from '../UI/TitleArea';
import AnswerForm from '../UI/AnswerForm';

const Sentence = (props) => {
    const { user } = useAuth();
    const params = useParams()
    const navigate = useNavigate();
    const [msg, setMsg] = useState([]);
    const [imagePath, setImagePath] = useState(null);
    const [videoPath, setVideoPath] = useState(null);
    const [soundPath, setSoundPath] = useState(null);
    const [serviceList, setServiceList] = useState([{ service: "" }]);




    const handletype7 = async (e) => {
        e.preventDefault();
        var answer = document.getElementById("correctAnswer").value;
        var question;
        let questiontext = document.getElementById("questionText").value;
        if (imagePath) {
            question = {
                type: "image",
                url: imagePath,
                text: questiontext
            }
        }
        else if (soundPath) {
            question = {
                type: "sound",
                url: soundPath,
                text: questiontext
            }
        }
        else if (videoPath) {
            question = {
                type: "video",
                url: videoPath,
                text: questiontext
            }
        }
        else {
            question = {
                type: "text",
                url: "",
                text: questiontext
            }
        }
        var stringquestion = JSON.stringify(question)
        var obj = []
        for (let i = 0; i < serviceList.length; i++) {
            let k = serviceList[i]['service'].split(',');
            let kcount = k.length
            let list = {
                id: i,
                service: k,
                answersCount: k.length
            }
            obj.push(list)
        }
        console.log(obj)
        var answertext = JSON.stringify(obj)
        var info = document.getElementById("infoBoxText").value;

        try {
            const data = {
                isDeleted: false,
                tagId: document.getElementById("tagId").value,
                sortId: params["sort"],
                contentType: "sentence",
                subjectId: params["subject"],
                contentStr: stringquestion,
                detail: answertext,
                notfyText: info,
                answer: answer
            };
            await GeneralRequestHelper("/api/admin/ContentManager/Create", "post", data, user.token).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Soru ekleme sayfasına Dön`,
                            showDenyButton: true,
                            denyButtonText: `Aynı konuya bir soru daha ekle`,
                            timer: 5000,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate("/dashboard/admin/createquestion");
                            } else if (result.isDenied) {
                                navigate("/dashboard/admin/createquestionbysubject/" + params["subject"]);
                            }
                        });
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    useEffect(() => {
    });

    return (
        <div>
            <TitleArea msg={msg} text={"sentence Oluşturma Sayfası"} />
            <Tag />
            <AddContentQuestion setMsg={setMsg} setImagePath={setImagePath} setVideoPath={setVideoPath} setSoundPath={setSoundPath} />
            <hr />
            <form onSubmit={handletype7} >
                <span> kelime seçeneklerinin olduğu yere alt çizgi koyunuz. ÖRN: my _ is ahmet. 1. alt çizgi yerine 1. cevap kısmında yazacağınız kelime seçenekleri gelecektir.</span>
                <QuestionTextInput />
                <CorrectAnswer text="Lütfen 1/2/3/4 şeklinde ve virgüllerle giriniz. 1,2 şeklinde. 1 alt çizgi için 3 farklı kelime seçeneği sunuldu. doğru olan kaçıncı ise rakamla o , 2.ci alt çizgi için sunulan kelimelerden hangisi doğru ise rakamla o .." />
                <InfoInput />
                <AnswerForm setServiceList={setServiceList} serviceList={serviceList} file={false} />
                <SubmitButton />
            </form>
            <hr />
            <hr />

        </div>
    );
};

export default Sentence;
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from '../../context/AuthContext';
import $ from "jquery";
import { BrowserView, MobileView } from 'react-device-detect';

import { useTranslation } from 'react-i18next';
import Navbar from '../Dashboard/DashboardComponents/Navbar';
import MobileNavbar from '../Dashboard/DashboardComponents/MobileNavbar';
import MobileNavbarManager from '../Dashboard/DashboardComponents/MobileNavbarManager';
import MobileNavbarAdmin from '../Dashboard/DashboardComponents/MobileNavbarAdmin';
import BrowserSidebarAdmin from '../Dashboard/DashboardComponents/BrowserSidebarAdmin';
import SidebarHeader from '../Dashboard/DashboardComponents/SidebarHeader';
import TeacherSidebarDetail from '../Dashboard/DashboardComponents/TeacherSidebarDetail'
import ManagerSidebarDetail from '../Dashboard/DashboardComponents/ManagerSidebarDetail'
import MobileNavbarStudent from '../Dashboard/DashboardComponents/MobileNavbarStudent'

function DashboardLayout() {
    const { user } = useAuth();
    const { t } = useTranslation();
    //i18n.changeLanguage("tr")


    function onClick(event) {
        if (event.currentTarget.id === "arrow" || event.currentTarget.id === "arroww") {
            var element = document.getElementById("sidebar");
            element.classList.toggle("active");
            $(".forsidebar").toggle();
        }
        else if (event.currentTarget.id === "arrowww") {
            var elementt = document.getElementById("sidebar");
            elementt.classList.toggle("active");
            //$(".forsidebar").toggle();
        }
    }





    useEffect(() => {


    }, []);
    if (!user) {
        window.location.href = "/auth/login";
    }
    else {
        if (user['role'] === "SysAdmin") {
            return (
                <div>
                    <BrowserView>
                        <Navbar />
                        <div class="wrapper">
                            <nav id="sidebar" style={{ backgroundColor: "SteelBlue" }}>
                                <SidebarHeader onClick={onClick} />
                                <BrowserSidebarAdmin />
                            </nav>
                            <div id="content">
                                <div className="container">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </BrowserView>


                    <MobileView>
                        <MobileNavbarAdmin />
                        <div>
                            <Outlet />
                        </div>
                    </MobileView>
                </div>

            );
        }
        else if (user['role'] === "Manager") {
            return (
                <div>
                    <BrowserView>
                        <Navbar />
                        <div class="wrapper">
                            <nav id="sidebar" style={{ backgroundColor: "SteelBlue" }}>
                                <SidebarHeader onClick={onClick} />
                                <ManagerSidebarDetail />
                            </nav>

                            <div id="content">
                                <div className="container">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </BrowserView >
                    <MobileView>
                        <MobileNavbarManager />
                        <div>
                            <Outlet />
                        </div>
                    </MobileView>
                </div >

            );
        }
        else if (user['role'] === "Student") {
            if (user.user.isTrial) {
                window.location.href = "/profile";
            }
            else {
                return (
                    <div>
                        <BrowserView>
                            <Navbar />
                            <div class="wrapper">
                                <nav id="sidebar" style={{ backgroundColor: "SteelBlue" }}>
                                    <SidebarHeader onClick={onClick} />
                                    <ul class="list-unstyled components ">
                                        <li class="active" id="general">
                                            <li>
                                                <a href="/dashboard/user/course" id="generals" style={{ color: "Gainsboro" }}>
                                                    <FontAwesomeIcon icon="fas fa-university" />
                                                    <span style={{ marginLeft: "4%" }}>
                                                        {t('course')}
                                                    </span>

                                                </a>
                                            </li>
                                        </li>
                                    </ul>

                                    <ul class="list-unstyled components ">
                                        <li class="active" id="general">
                                            <li>
                                                <a href="/dashboard/usergeneral" id="generals" style={{ color: "Gainsboro" }}>
                                                    <FontAwesomeIcon icon="fas fa-university" />
                                                    <span style={{ marginLeft: "4%" }}>
                                                        {t('generalreport')}
                                                    </span>

                                                </a>
                                            </li>
                                        </li>
                                        <br />
                                        <li class="active" id="skills">
                                            <li>
                                                <a href="/dashboard/userskill" id="skillss" style={{ color: "Gainsboro" }}>
                                                    <FontAwesomeIcon icon="fas fa-chart-bar" />
                                                    <span style={{ marginLeft: "4%" }}>
                                                        {t('skills')}
                                                    </span>
                                                </a>
                                            </li>
                                        </li>
                                        <br />
                                        <li class="active" id="progress">
                                            <li>
                                                <a href="/dashboard/userprogress" id="progresss" style={{ color: "Gainsboro" }}>
                                                    <FontAwesomeIcon icon="fa-solid fa-chart-pie" />
                                                    <span style={{ marginLeft: "4%" }}>
                                                        {t('progress')}
                                                    </span>
                                                </a>
                                            </li>
                                        </li>
                                        <br />



                                        <li class="active" id="list">
                                            <li>
                                                <a href="/dashboard/userlist" id="lists" style={{ color: "Gainsboro" }}>
                                                    <i class="fas fa-list"></i>
                                                    <span style={{ marginLeft: "4%" }}>
                                                        List
                                                    </span>
                                                </a>
                                            </li>
                                        </li>
                                    </ul>
                                </nav>

                                <div id="content">
                                    <div className="container">
                                        <Outlet />
                                    </div>
                                </div>
                            </div>
                        </BrowserView>
                        <MobileView>
                            <MobileNavbarStudent />
                            <Outlet />
                        </MobileView>
                    </div>

                );
            }

        }
        else if (user['role'] === "Teacher") {
            return (
                <div>
                    <BrowserView>
                        <Navbar />
                        <div class="wrapper">
                            <nav id="sidebar" style={{ backgroundColor: "SteelBlue" }}>
                                <SidebarHeader onClick={onClick} />
                                <TeacherSidebarDetail />

                            </nav>

                            <div id="content">
                                <div className="container">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </BrowserView>
                    <MobileView>
                        <MobileNavbar />
                        <div>
                            <Outlet />
                        </div>
                    </MobileView>
                </div>
            );
        }
    }

}


export default DashboardLayout;

import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'

const VideoContent = (props) => {
    const [url, setUrl] = useState([]);
    const [questionId, setQuestionId] = useState([]);
    const onClick = () => {
        window.location.href = "/dashboard/admin/editquestion/" + questionId;
    }

    useEffect(() => {
        setUrl(props.data['contentStr'])
        setQuestionId(JSON.parse(props.data.id));

    });

    return (
        <div>
            <hr />

            <div style={{ width: "30%", margin: "auto" }}>
                <div class="d-flex justify-content-center">
                    <div className='player-wrapper' style={{ padding: "5%" }}>
                        <ReactPlayer
                            className='react-player'
                            url={url}
                            width='100%'
                            height='100%'
                            controls="true"
                        />
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <button className='btn btn-info' onClick={onClick}>Soruyu Düzenle</button>
            </div>
        </div>
    );
};

export default VideoContent;
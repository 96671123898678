import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'
import axios from "axios";
import QuestionContents from './UI/QuestionContents';
import { useAuth } from '../../../../../context/AuthContext';
import { useParams } from "react-router-dom";
const TrueFalse = (props) => {
    const { t, i18n } = useTranslation()
    const [msg, setMsg] = useState([]);
    const [question, setQuestion] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [questionTitle, setQuestionTitle] = useState([]);
    const [questionText, setQuestionText] = useState([]);
    const { user } = useAuth();
    const params = useParams();

    const Request = async (userAnswer) => {
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/user/Content/Answer";
            await axios({
                method: 'GET',
                url: url,
                params: {
                    questionId: props.questionNumber,
                    answer: userAnswer.toString()
                },
                headers: headerss
            }).then(res => {
                if (res.data['success']) {
                    let audio = new Audio("https://api.heryerdeingilizce.com/sound/d6350096-7457-46bc-bcaa-313a33970558.mp4")
                    audio.play();
                    localStorage.setItem("count", 0)
                    Swal.fire({
                        title: "Başırılı!",
                        icon: "success",
                        confirmButtonText: `Tamam`,
                        timer: 3000,
                    }).then(() => {
                        let newurl = Number(params['id']) + 1;
                        window.location.href = "/dashboard/user/course/question/" + params['subjectid'] + "/" + newurl;

                    });
                }
                else {
                    if (res['data']['message'] === "Bu soru daha önce çözülmüş") {
                        localStorage.setItem("count", 0)

                        Swal.fire({
                            title: "Hata!",
                            text: "Bu soru daha önce çözülmüş",
                            icon: "error",
                            confirmButtonText: `Tamam`,
                        }).then(() => {
                            let newurl = Number(params['id']) + 1;
                            window.location.href = "/dashboard/user/course/question/" + params['subjectid'] + "/" + newurl;
                        });


                    }
                    else {
                        let audio = new Audio("https://api.heryerdeingilizce.com/sound/f174007c-0602-4455-8463-ae6a3285f02c.mp4")
                        audio.play();
                        localStorage.setItem("count", 0)
                        Swal.fire({
                            title: "Hatalı Cevap!",
                            text: props.data.notfyText,
                            icon: "error",
                            confirmButtonText: `Tamam`,
                        }).then(() => {
                            let newurl = Number(params['id']) + 1;
                            window.location.href = "/dashboard/user/course/question/" + params['subjectid'] + "/" + newurl;
                        });
                    }

                }
            });
        }
        catch (err) {
            setMsg(err.message);
        }
    }

    const answerTrueFalseHandle = async (e) => {
        let value = localStorage.getItem("count")

        var userAnswer = ""
        for (let i = 0; i < props.truefalseanswercount; i++) {
            if (i === props.truefalseanswercount - 1) {
                if (document.getElementById(i + ".true").checked) {
                    userAnswer += "true";
                }
                else if (document.getElementById(i + ".false").checked) {
                    userAnswer += "false";
                }
                else {
                    alert("boş bırakmayınız.")
                }
            }
            else {
                if (document.getElementById(i + ".true").checked) {
                    userAnswer += "true,";
                }
                else if (document.getElementById(i + ".false").checked) {
                    userAnswer += "false,";
                }
                else {
                    alert("boş bırakmayınız.")
                }
            }
        }

        const fetchdata = async () => {
            if (parseInt(value) === 0) {
                if (userAnswer === props.answer) {
                    setMsg(Request(userAnswer))
                }
                else {
                    let audio = new Audio("https://api.heryerdeingilizce.com/sound/f174007c-0602-4455-8463-ae6a3285f02c.mp4")
                    audio.play();
                    Swal.fire({

                        title: "Tekrar Deneyiniz!",
                        text: props.data.notfyText,
                        icon: "warning",
                        confirmButtonText: `Tamam`,
                    }).then(() => {
                        window.location.reload();
                    });
                }
            }
            else if (parseInt(value) === 1) {
                setMsg(Request(userAnswer))
            }
            localStorage.setItem("count", parseInt(value) + 1)
        }
        e.preventDefault();
        fetchdata();
    }

    useEffect(() => {
        let value = localStorage.getItem("count")
        if (value === null) {
            localStorage.setItem("count", 0)
        }
        setQuestion(JSON.parse(props.data.contentStr));
        setAnswers(JSON.parse(props.data.detail));
        let que = JSON.parse(props.data.contentStr)

        var questionTitlee = que['text'].split('__')[0];
        let questionTextt = que['text'].split('__')[1];
        setQuestionTitle(questionTitlee);
        setQuestionText(questionTextt);
    }, []);
    return (
        <div>
            <div style={{ alignItems: "center", textAlign: "center" }}>
                <QuestionContents question={question} />


                <br /><br />
                <h3>
                    {questionTitle}
                </h3>
                <br /> <br />
                <h4>
                    {questionText}
                </h4>
                <br /><hr />
            </div>
            <div class="d-flex justify-content-center" style={{ fontSize: 20 }}>
                <form onSubmit={answerTrueFalseHandle} >

                    <div className="row" >

                        {answers && answers.map((dt) => {
                            return (
                                <div className='row' >
                                    <div className='col-md-8' style={{ textAlign: "center" }}>
                                        <h4> {dt['service']}</h4>
                                    </div>
                                    <div className='col-md-4' style={{ textAlign: "center" }}>

                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name={"inlineRadioOptions" + dt.id} id={dt.id + ".true"} />
                                            <label class="form-check-label" for={dt.id + ".true"}><b>True</b></label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name={"inlineRadioOptions" + dt.id} id={dt.id + ".false"} />
                                            <label class="form-check-label" for={dt.id + ".false"}><b>False</b></label>
                                        </div>
                                    </div>
                                    <hr />
                                </div>

                            )
                        })}
                        <br />

                    </div>
                    <br /><br />
                    {props.answered ? (
                        <h4> cevaplanmış <i>{props.realanswer}</i> </h4>
                    ) : (
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t("saveAndContinue")}</button>
                        </div>
                    )}

                </form>
            </div>
            <div class="d-flex justify-content-center">
            </div>
            <br /><hr />
        </div>
    );
};

export default TrueFalse;
import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import QuestionContents from './UI/QuestionContents';

const TrueFalse = (props) => {
    const { t, i18n } = useTranslation()
    const params = useParams();
    const [msg, setMsg] = useState([]);
    const [question, setQuestion] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [questionTitle, setQuestionTitle] = useState([]);
    const [questionText, setQuestionText] = useState([]);
    const [questionId, setQuestionId] = useState([]);

    const onClick = () => {
        window.location.href = "/dashboard/admin/editquestion/" + questionId;
    }


    useEffect(() => {
        setQuestionId(JSON.parse(props.data.id));

        setQuestion(JSON.parse(props.data.contentStr));
        setAnswers(JSON.parse(props.data.detail));
        let que = JSON.parse(props.data.contentStr)

        var questionTitlee = que['text'].split('__')[0];
        let questionTextt = que['text'].split('__')[1];
        setQuestionTitle(questionTitlee);
        setQuestionText(questionTextt);
    }, []);
    return (
        <div>
            <hr />
            <div style={{ alignItems: "center", textAlign: "center" }}>
                <QuestionContents question={question} />
                <h5>
                    {questionTitle}
                </h5>
                <br />
                <h6>
                    {questionText}
                </h6>
                <hr />
            </div>
            <div class="d-flex justify-content-center" style={{ fontSize: 20 }}>
                <div className="row" >

                    {answers && answers.map((dt) => {
                        return (
                            <div className='row' >
                                <div className='col-md-8' style={{ textAlign: "center" }}>
                                    <h4> {dt['service']}</h4>
                                </div>
                                <div className='col-md-4' style={{ textAlign: "center" }}>

                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name={"inlineRadioOptions" + dt.id} id={dt.id + ".true"} />
                                        <label class="form-check-label" for={dt.id + ".true"}><b>True</b></label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name={"inlineRadioOptions" + dt.id} id={dt.id + ".false"} />
                                        <label class="form-check-label" for={dt.id + ".false"}><b>False</b></label>
                                    </div>
                                </div>
                                <hr />
                            </div>

                        )
                    })}
                    <br />

                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <button className='btn btn-info' onClick={onClick}>Soruyu Düzenle</button>
            </div>
        </div>
    );
};

export default TrueFalse;
import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { VictoryPie, VictoryLabel } from 'victory';
import data from '../ExampleData/skilldata.json';
import { useAuth } from '../../../../context/AuthContext';
import axios from "axios";
import { useTranslation } from "react-i18next";

function UserSkill() {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const [listening, setListening] = useState(0)
    const [reading, setReading] = useState(0)
    const [writing, setWriting] = useState(0)
    const [speaking, setSpeaking] = useState(0)
    const [listeningtrue, setListeningtrue] = useState(0)
    const [readingtrue, setReadingtrue] = useState(0)
    const [writingtrue, setWritingtrue] = useState(0)
    const [speakingtrue, setSpeakingtrue] = useState(0)

    const [loading, setLoading] = useState(true);

    const handleClick = event => {
        console.log(event.currentTarget.id);
        $('#GrammerButton').css("backgroundColor", "GhostWhite");
        $('#VocabularyButton').css("backgroundColor", "GhostWhite");
        $('#ListeningButton').css("backgroundColor", "GhostWhite");
        $('#ReadingButton').css("backgroundColor", "GhostWhite");
        let buttonBackground = "#" + event.currentTarget.id + "Button";
        $(buttonBackground).css("backgroundColor", "white");
        let detaylar = event.currentTarget.id + "detay";

        data && data.map((dt) => {
            let detay = document.getElementById(dt.typeInfoId);
            console.log(document.getElementById(dt.typeInfoId));
            detay.style.display = "none";
        })

        let detay = document.getElementById(detaylar);
        detay.style.visibility = "visible";
        detay.style.display = ""
    };
    const fetchdata = async () => {
        setLoading(true);
        let list = 0
        let listtrue = 0
        let read = 0
        let readtrue = 0
        let write = 0
        let writetrue = 0
        let speak = 0
        let speaktrue = 0
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/user/Opt/MyReport";
            await axios({
                method: 'GET',
                url: url,
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    console.log(res.data['data'])
                    for (let i = 0; i < res.data['data'].length; i++) {

                        if (res.data['data'][i]['tagId'] === 1) {
                            list += 1
                            if (res.data['data'][i]['isTrue']) {
                                listtrue += 1
                            }
                        }
                        if (res.data['data'][i]['tagId'] === 2) {
                            write += 1
                            if (res.data['data'][i]['isTrue']) {
                                writetrue += 1
                            }
                        }
                        if (res.data['data'][i]['tagId'] === 4) {
                            read += 1
                            if (res.data['data'][i]['isTrue']) {
                                readtrue += 1

                            }
                        }
                        if (res.data['data'][i]['tagId'] === 5) {
                            speak += 1
                            if (res.data['data'][i]['isTrue']) {
                                speaktrue += 1
                            }
                        }
                    }

                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err);
        }

        setReading(read)
        setReadingtrue(readtrue)
        setSpeaking(speak)
        setSpeakingtrue(speaktrue)
        setWritingtrue(writetrue)
        setWriting(write)
        setListeningtrue(listtrue)
        setListening(list)

        setLoading(false);
    }
    useEffect(() => {
        fetchdata()
    }, [])

    return (
        <div style={{ marginLeft: "3%", marginRight: "3%" }}>
            {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
            {!loading && (
                <div>
                    <div class="alert alert-light" role="alert">
                        <div className="row">
                            <div className="col-sm">
                                <svg viewBox="0 0 400 300">
                                    <VictoryPie
                                        standalone={false}
                                        width={400} height={400}
                                        data={[
                                            { x: " ", y: listeningtrue }, { x: " ", y: listening - listeningtrue }
                                        ]}
                                        startAngle={90}
                                        endAngle={-90}
                                        colorScale={["Blue", "Aqua"]}
                                        innerRadius={111} labelRadius={100}
                                        style={{ labels: { fontSize: 20, fill: "white" } }}
                                    />
                                    <VictoryLabel
                                        textAnchor="middle"
                                        style={{ fontSize: 22 }}
                                        x={200} y={200}
                                        text={listeningtrue + "/" + listening + "#Listening/Dinleme"}
                                    />
                                </svg>
                            </div>
                            <div className="col-sm">
                                <svg viewBox="0 0 400 300">
                                    <VictoryPie
                                        standalone={false}
                                        width={400} height={400}
                                        data={[
                                            { x: " ", y: readingtrue }, { x: " ", y: reading - readingtrue }
                                        ]}
                                        startAngle={90}
                                        endAngle={-90}
                                        colorScale={["Blue", "Aqua"]}
                                        innerRadius={111} labelRadius={100}
                                        style={{ labels: { fontSize: 20, fill: "white" } }}
                                    />
                                    <VictoryLabel
                                        textAnchor="middle"
                                        style={{ fontSize: 22 }}
                                        x={200} y={200}
                                        text={readingtrue + "/" + reading + "#Reading/Okuma"}

                                    />
                                </svg>

                            </div>
                            <div className="col-sm">
                                <svg viewBox="0 0 400 300">
                                    <VictoryPie
                                        standalone={false}
                                        width={400} height={400}
                                        data={[
                                            { x: " ", y: writingtrue }, { x: " ", y: writing - writingtrue }
                                        ]}
                                        startAngle={90}
                                        endAngle={-90}
                                        colorScale={["Blue", "Aqua"]}
                                        innerRadius={111} labelRadius={100}
                                        style={{ labels: { fontSize: 20, fill: "white" } }}
                                    />
                                    <VictoryLabel
                                        textAnchor="middle"
                                        style={{ fontSize: 25 }}
                                        x={200} y={200}
                                        text={writingtrue + "/" + writing + "#Writing/Yazma"}

                                    />
                                </svg>

                            </div>
                            <div className="col-sm">
                                <svg viewBox="0 0 400 300">
                                    <VictoryPie
                                        standalone={false}
                                        width={400} height={400}
                                        data={[
                                            { x: " ", y: speakingtrue }, { x: " ", y: speaking - speakingtrue }
                                        ]}
                                        startAngle={90}
                                        endAngle={-90}
                                        colorScale={["Blue", "Aqua"]}
                                        innerRadius={111} labelRadius={100}
                                        style={{ labels: { fontSize: 20, fill: "white" } }}
                                    />
                                    <VictoryLabel
                                        textAnchor="middle"
                                        style={{ fontSize: 25 }}
                                        x={200} y={200}
                                        text={speakingtrue + "/" + speaking + "#Speaking/Konuşma"}

                                    />
                                </svg>

                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ backgroundColor: "GhostWhite", margin: "center", marginLeft: "0%", marginRight: "0%" }}>
                        <div className="row"><br /></div>
                        <div className="col-sm">
                            <div
                                class="alert alert-light"
                                role="alert" id="GrammerButton"
                                style={{ alignItems: "center", textAlign: "center", backgroundColor: "white" }}
                            >
                                <button type="button" id="Grammer" class="btn btndark" onClick={handleClick}>
                                    Grammar
                                </button>
                            </div>
                        </div>

                        <div className="col-sm">
                            <div
                                class="alert alert-light"
                                role="alert" id="VocabularyButton"
                                style={{ alignItems: "center", textAlign: "center", backgroundColor: "GhostWhite" }}
                            >
                                <button type="button" id="Vocabulary" class="btn btndark" onClick={handleClick}>
                                    Vocabulary
                                </button>
                            </div>
                        </div>

                        <div className="col-sm">
                            <div
                                class="alert alert-light"
                                role="alert" id="ReadingButton"
                                style={{ alignItems: "center", textAlign: "center", backgroundColor: "GhostWhite" }}
                            >
                                <button type="button" id="Reading" class="btn btndark" onClick={handleClick}>
                                    Reading
                                </button>
                            </div>
                        </div>

                        <div className="col-sm">
                            <div id="ListeningButton" class="alert alert-light" role="alert" style={{ alignItems: "center", textAlign: "center", backgroundColor: "GhostWhite" }} >
                                <button type="button" id="Listening" onClick={handleClick} class="btn btndark">
                                    Listening
                                </button>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
            )}


            {data && data.map((dt) => {
                if (dt.typeId === 1) {
                    return (
                        <div key={dt.typeId} style={{ visibility: "visible" }}>
                            <div class="alert alert-light" role="alert" id={dt.typeInfoId} style={{ backgroundColor: "white" }}>
                                {dt.data.map((details) => {
                                    return (
                                        <div key={details.id} role="alert">
                                            <div className="row" style={{ marginLeft: "0%" }}>
                                                <div className="col-md-9">
                                                    <h5>{details.title}</h5>
                                                </div>
                                                <div className="col-md-3" style={{ textAlign: "right" }}>
                                                    <h5>{details.ratio}</h5>
                                                </div>
                                            </div>
                                            <div className="row progress" style={{ marginLeft: "1%", marginTop: "1%", marginRight: "1%", color: "white", height: "2%" }}>
                                                <div class="progress-bar" role="progressbar" className={details.color} style={{ width: details.realratio }}>
                                                    <h5>
                                                        {details.realratio}
                                                    </h5>
                                                </div>
                                            </div>
                                            <div style={{ textAlign: "center", marginTop: "1%" }}>
                                                <i class="fa fa-arrow-down" style={{ fontSize: "20" }} aria-hidden="true" data-bs-toggle="collapse" data-bs-target={"#" + details.titleaccordion} aria-expanded="true" aria-controls={details.titleaccordion}></i>
                                            </div>
                                            <div id={details.titleaccordion} class="accordion-collapse collapse">
                                                <div class="accordion-body" style={{ marginTop: "3%" }}>
                                                    {details.details.map((detaylar) => {
                                                        return (
                                                            <div className="row" key={detaylar.id} style={{ marginTop: "1%" }}>
                                                                <div className="col-sm" style={{ textAlign: "right" }}>
                                                                    <h6>{detaylar.name}</h6>
                                                                </div>
                                                                <div className="col-sm">
                                                                    <div class="progress" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                                        <div class="progress-bar" className={detaylar.color} style={{ width: detaylar.ratio }}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                    )
                }
                else {
                    return (
                        <div key={dt.typeId} style={{ visibility: "hidden" }}>
                            <div class="alert alert-light" role="alert" id={dt.typeInfoId} style={{ backgroundColor: "white" }}>
                                {dt.data.map((details) => {
                                    return (
                                        <div key={details.id} role="alert">
                                            <div className="row" style={{ marginLeft: "0%" }}>
                                                <div className="col-md-9">
                                                    <h5>{details.title}</h5>
                                                </div>
                                                <div className="col-md-3">
                                                    <h5>{details.ratio}</h5>
                                                </div>
                                            </div>
                                            <div className="row progress" style={{ marginLeft: "1%", marginTop: "1%", marginRight: "1%", color: "white", height: "2%" }}>
                                                <div class="progress-bar" role="progressbar" className={details.color} style={{ width: details.realratio }}>
                                                    <h5>{details.realratio}</h5>
                                                </div>

                                            </div>
                                            <div style={{ textAlign: "center", marginTop: "1%" }}>
                                                <i class="fa fa-arrow-down" style={{ fontSize: "20" }} aria-hidden="true" data-bs-toggle="collapse" data-bs-target={"#" + details.titleaccordion} aria-expanded="true" aria-controls={details.titleaccordion}></i>
                                            </div>
                                            <div id={details.titleaccordion} class="accordion-collapse collapse">
                                                <div class="accordion-body" style={{ marginTop: "3%" }}>
                                                    {details.details.map((detaylar) => {
                                                        return (
                                                            <div className="row" key={detaylar.id} style={{ marginTop: "1%" }}>
                                                                <div className="col-sm" style={{ textAlign: "right" }}>
                                                                    <h6>{detaylar.name}</h6>
                                                                </div>
                                                                <div className="col-sm">
                                                                    <div class="progress" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                                        <div class="progress-bar" className={detaylar.color} style={{ width: detaylar.ratio }}></div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )
                                                    })}

                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                }

            })
            }

        </div>
    );
}
export default UserSkill;

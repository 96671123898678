import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from '../../../../../../../context/AuthContext';
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";


export const AdminQuestionList = () => {
    const [loading, setLoading] = useState(true);
    const { user } = useAuth();
    const [msg, setMsg] = useState([]);
    const { t, i18n } = useTranslation()
    const [subjects, setSubjects] = useState([]);
    const [courses, setCourses] = useState([]);

    const navigate = useNavigate();




    const onSubmit = async (e) => {
        try {
            let subject = await document.getElementById("subjectId").value;
            navigate("/dashboard/admin/questionlistbysubject/" + subject);
        } catch (error) {
            alert(error.message);
        }
    }
    const onChange = async (e) => {
        setSubjects(subjects => [])

        const fetchdataa = async () => {
            setLoading(true);
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/SubjectManager/GetListByCourse";
                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss,
                    params: {
                        id: e.target.value
                    }
                }).then(res => {
                    if (res.status === 200) {
                        if (res.data['success']) {
                            for (let i = 0; i < res.data['data'].length; i++) {
                                if (res.data['data'][i]['isActive']) {
                                    setSubjects(subjects => [...subjects, res.data['data'][i]])
                                }
                            }
                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdataa();
    }

    useEffect(() => {
        const fetchdataa = async () => {
            try {
                const headerss = {
                    "accept": "*/*",
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': 'false',
                    'Authorization': 'Bearer ' + user.token
                }
                let url = "https://api.heryerdeingilizce.com/api/admin/CourseManager/GetCourses";

                await axios({
                    method: 'GET',
                    url: url,
                    headers: headerss
                }).then(res => {

                    if (res.status === 200) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            if (res.data['data'][i]['isDeleted'] === false) {
                                if (res.data['data'][i]['isActive']) {
                                    courses.push({
                                        "id": res.data['data'][i]['id'],
                                        "courseName": res.data['data'][i]['courseName'],
                                        "courseDesc": res.data['data'][i]['courseDesc']
                                    })
                                }
                            }
                        }
                        if (res.data['success']) {

                        }
                        else {
                            setMsg(res.data['message']);
                        }
                    }
                    else {
                        setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                    }
                });
            }
            catch (err) {
                setMsg(err);
            }
            setLoading(false);
        }
        fetchdataa();
    }, [])
    return (
        <div className="container">
            <div style={{ textAlign: "center" }}>
                <h1>{t('questionList')}</h1>
            </div>
            <div>
                <h5>{t('getQuestionListBySubject')}</h5>
                <br />
                <div class="mb-3">
                    <label for="courseId" class="form-label">{t("selectCourse")}</label>
                    <select id="courseId" class="form-select" onChange={onChange}>
                        <option key="" >{t("selectCourse")}</option>

                        {courses ? (
                            courses && courses.map((dt) => {
                                return (
                                    <option key={dt.id} value={dt.id}>{dt.courseName} -- {dt.courseDesc}</option>
                                );
                            })
                        ) : (
                            <p>{t("reloadWarning")}</p>
                        )
                        }
                    </select>
                </div>
                <br />
                {loading && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
                {!loading && (
                    <form onSubmit={onSubmit} >
                        <div class="mb-3">
                            <label for="subjectId" class="form-label">{t("selectSubject")}</label>
                            <select id="subjectId" class="form-select">
                                <option key="" >{t("selectSubject")}</option>

                                {subjects ? (
                                    subjects && subjects.map((dt) => {
                                        return (
                                            <option key={dt.id} value={dt.id}>{dt.subjectName} // {dt.subjectDesc}</option>
                                        );
                                    })
                                ) : (
                                    <p>{t("reloadWarning")}</p>
                                )
                                }
                            </select>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button type="submit" class="btn btn-primary btn-lg">{t("getQuestionList")}</button>
                        </div>
                    </form>
                )}
            </div>
            <hr />

        </div>
    );
};
export default AdminQuestionList;

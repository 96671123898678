import React from 'react';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../context/AuthContext';

const GeneralProfile = (props) => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();

    function handleSubmit(event) {
    }
    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <h3>{t("profile")}</h3>
            </div>
            <form onSubmit={handleSubmit} style={{ border: "1px solid black", padding: "2%" }}>
                <div class="mb-3">
                    <label for="name" class="form-label">{t("namesurname")}</label>
                    <textarea class="form-control" id="name" defaultValue={user.user.fullName} />
                </div>

                <div class="mb-3">
                    <label for="email" class="form-label">{t("email")}</label>
                    <textarea class="form-control" id="email" defaultValue={user.user.username} />
                </div>
                <div className='d-flex justify-content-center'>
                    <button type="submit" class="btn btn-primary btn-lg">{t("update")}</button>
                </div>

            </form>
            <br />
            <hr />
        </div>
    );
};

export default GeneralProfile;
import React, { useState, useEffect } from "react";
import { useAuth } from '../../../../../../context/AuthContext';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import GeneralRequestHelper from '../../../../../../Helpers/GeneralRequestHelper';

export const AdminCreateSection = () => {
    const { user } = useAuth();
    const { t } = useTranslation()
    const [msg, setMsg] = useState([]);
    const [SortIdValue, setSortIdValue] = useState([]);

    const onSubmit = async (e) => {
        e.preventDefault();
        if (SortIdValue === 0) {
            window.location.reload();
        }
        try {
            const data = {
                sectionName: document.getElementById("sectionName").value,
                sectionDescription: document.getElementById("sectionDescription").value,
                sortId: SortIdValue,
                isActive: true
            }
            await GeneralRequestHelper("/api/admin/SectionManager/Create", "post", data, user.token).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        Swal.fire({
                            title: "Başırılı!",
                            icon: "success",
                            confirmButtonText: `Tamam`,
                            timer: 3000,
                        }).then(() => {
                            window.location.reload();
                        })
                    }
                    else {
                        setMsg(res.data['message']);
                        Swal.fire({
                            title: res.data['message'],
                            icon: "error",
                            timer: 3000,
                            confirmButtonText: `Tamam`
                        }).then(() => {
                            window.location.reload();
                        });
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    const getSections = async () => {
        try {
            await GeneralRequestHelper("/api/admin/SectionManager/GetList", "get", "", user.token).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        if (res.data['data'].length !== 0) {
                            setSortIdValue(Math.max.apply(Math, res.data['data'].map(function (o) { return o.sortId; })) + 1)
                        }
                        else {
                            setSortIdValue(1)
                        }
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        } catch (err) {
            setMsg(err.message);
        }
    }
    useEffect(() => {

        setSortIdValue(0)
        getSections();
    }, [])

    return (
        <div>
            <br />
            <div className="card">
                <div className="card-header d-flex justify-content-center">
                    <h2>{t('createSection')}</h2>
                </div>
                <h6>{msg}</h6>
                <div className="card-body">
                    <form onSubmit={onSubmit}>

                        <div class="mb-3">
                            <label for="sectionName" class="form-label">{t('sectionName')}</label>
                            <textarea class="form-control" name="sectionName" id="sectionName" required />
                        </div>
                        <div class="mb-3">
                            <label for="sectionDescription" class="form-label">{t('sectionDescription')}</label>
                            <textarea class="form-control" name="sectionDescription" id="sectionDescription" required />
                        </div>
                        <div className="d-flex justify-content-center">
                            <button type="submit" class="btn btn-primary"> {t('create')} </button>
                        </div>
                    </form>
                </div>
                <div className="card-footer">

                </div>
            </div>

        </div>
    );
}

export default AdminCreateSection;
import React, { useState, useEffect } from 'react';
import QuestionContents from './UI/QuestionContents';

const Match = (props) => {
    const [answers, setAnswers] = useState([]);
    const [question, setQuestion] = useState([]);
    const [questionId, setQuestionId] = useState([]);

    const onClick = () => {
        window.location.href = "/dashboard/admin/editquestion/" + questionId;
    }

    useEffect(() => {
        setQuestion(JSON.parse(props.data.contentStr));
        setAnswers(JSON.parse(props.data.detail));
        setQuestionId(JSON.parse(props.data.id));
    }, []);

    return (
        <div>
            <hr />

            <div style={{ alignItems: "center", textAlign: "center" }}>
                <QuestionContents question={question} />
                <br />
                <h4>
                    {question['text']}
                </h4>
                <hr />
            </div>
            <div class="d-flex justify-content-center">
                <div className="row">
                    {answers && answers.map((dt) => {
                        return (
                            <div class="col" >
                                <span><u id={dt.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></span>
                            </div>
                        )
                    })}
                </div>
            </div>
            <br />
            <div class="d-flex justify-content-center" >
                <div className="row">
                    {answers && answers.map((dt) => {
                        return (
                            <div class="col" >
                                <button className="btn btn-info" type="button" id={dt.id + ".button"} >
                                    {dt['service']}
                                </button>
                                {dt['photo'] ? (
                                    <img src={dt['photo']} />
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
            <br />
            <div className='d-flex justify-content-center'>
                <button className='btn btn-info' onClick={onClick}>Soruyu Düzenle</button>
            </div>
        </div>
    );
};

export default Match;
import React, { useState, useEffect } from "react";
import { useAuth } from '../../context/AuthContext';
import './rating.css'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import Swal from 'sweetalert2'
import { Alert } from "bootstrap";
import ChangePassword from "./UI/ChangePassword";
import Review from "./UI/Review";
import StudentTicket from "./UI/StudentTicket";
import ManagerTicket from "./UI/ManagerTicket";
import PaymentMethodList from "./UI/PaymentMethodList";
import PaymentRequest from "./UI/PaymentRequest";
import PackagePrice from "./UI/PackagePrice";
import GeneralProfile from "./UI/GeneralProfile";
import { BrowserView, MobileView } from 'react-device-detect';

function Profile() {
    const { user } = useAuth();
    const { setUser } = useAuth();
    const { t, i18n } = useTranslation();
    const [msg, setMsg] = useState([]);
    const [selectpackagemessage, setselectpackagemessage] = useState(true);
    const navigate = useNavigate();


    function handleLogout() {
        setUser(false);
        navigate("/auth/login");



    }

    function handleBack() {
        navigate("/Dashboard");
    }

    function onClick(event) {

        let account = document.getElementById("account");
        let bank = document.getElementById("bank");
        if (event.currentTarget.id === "profile") {
            document.getElementById("profile").classList.add("active");
            document.getElementById("editprofile").classList.remove("active");
            document.getElementById("review").classList.remove("active");
            document.getElementById("profiledetail").style.display = "inline";
            document.getElementById("reviewdetail").style.display = "none";


            if (user.user.roleName != "Student") {
                document.getElementById("ticket").classList.remove("active");
                document.getElementById("ticketdetail").style.display = "none";
            }
            if (account) {
                account.classList.remove("active");
                document.getElementById("accountdetail").style.display = "none";
            }
            if (bank) {
                bank.classList.remove("active");
                document.getElementById("bankdetail").style.display = "none";
            }
        }
        else if (event.currentTarget.id === "editprofile") {
            document.getElementById("profile").classList.remove("active");
            document.getElementById("editprofile").classList.add("active");
            document.getElementById("review").classList.remove("active");
            document.getElementById("ticket").classList.remove("active");

            document.getElementById("editprofilee").style.display = "inline";
            document.getElementById("profiledetail").style.display = "none";
            document.getElementById("reviewdetail").style.display = "none";
            document.getElementById("ticketdetail").style.display = "none";
            if (account) {
                account.classList.remove("active");
                document.getElementById("accountdetail").style.display = "none";
            }
            if (bank) {
                bank.classList.remove("active");
                document.getElementById("bankdetail").style.display = "none";
            }
        }
        else if (event.currentTarget.id === "account") {
            document.getElementById("editprofile").classList.remove("active");
            document.getElementById("profile").classList.remove("active");
            document.getElementById("account").classList.add("active");
            document.getElementById("bank").classList.remove("active");
            document.getElementById("review").classList.remove("active");

            document.getElementById("editprofilee").style.display = "none";

            document.getElementById("profiledetail").style.display = "none";
            document.getElementById("accountdetail").style.display = "inline";
            document.getElementById("bankdetail").style.display = "none";
            document.getElementById("reviewdetail").style.display = "none";
            document.getElementById("ticket").classList.remove("active");
            document.getElementById("ticketdetail").style.display = "none";
        }
        else if (event.currentTarget.id === "bank") {
            document.getElementById("profile").classList.remove("active");
            document.getElementById("editprofile").classList.remove("active");
            document.getElementById("account").classList.remove("active");
            document.getElementById("bank").classList.add("active");
            document.getElementById("review").classList.remove("active");

            document.getElementById("editprofilee").style.display = "none";
            document.getElementById("profiledetail").style.display = "none";
            document.getElementById("accountdetail").style.display = "none";
            document.getElementById("bankdetail").style.display = "inline";
            document.getElementById("reviewdetail").style.display = "none";
            document.getElementById("ticket").classList.remove("active");
            document.getElementById("ticketdetail").style.display = "none";
        }
        else if (event.currentTarget.id === "ticket") {
            document.getElementById("profile").classList.remove("active");
            document.getElementById("editprofile").classList.remove("active");
            document.getElementById("review").classList.remove("active");

            document.getElementById("editprofilee").style.display = "none";
            document.getElementById("profiledetail").style.display = "none";
            document.getElementById("reviewdetail").style.display = "none";
            document.getElementById("ticket").classList.add("active");
            document.getElementById("ticketdetail").style.display = "inline";
            if (account) {
                account.classList.remove("active");
                document.getElementById("accountdetail").style.display = "none";
            }
            if (bank) {
                bank.classList.remove("active");
                document.getElementById("bankdetail").style.display = "none";
            }
        }
        else if (event.currentTarget.id === "review") {
            document.getElementById("editprofile").classList.remove("active");

            document.getElementById("profile").classList.remove("active");
            document.getElementById("review").classList.add("active");
            document.getElementById("editprofilee").style.display = "none";

            document.getElementById("profiledetail").style.display = "none";
            document.getElementById("reviewdetail").style.display = "inline";
            document.getElementById("ticket").classList.remove("active");
            document.getElementById("ticketdetail").style.display = "none";
            if (account) {
                account.classList.remove("active");
                document.getElementById("accountdetail").style.display = "none";
            }
            if (bank) {
                bank.classList.remove("active");
                document.getElementById("bankdetail").style.display = "none";
            }
        }
    }

    useEffect(() => {
        // üye istrial true ise
        if (user.user.isTrial) {
            if (user.userDetail.sectionId === 1) {
                setselectpackagemessage("Lütfen bölümünüzü seçiniz.");
            }
        }
        // üye istrial true ise
        document.getElementById("profile").classList.add("active");
        document.getElementById("profiledetail").style.display = "inline";

    }, []);


    return (
        <div>
            <BrowserView>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3" style={{ marginTop: "3%" }}>
                            <div class="list-group list-group-flush" >
                                <button style={{ marginBottom: "5%" }} className="btn btn-info" onClick={handleBack}>Panele Dön</button>
                                <a class="list-group-item list-group-item-action" id="profile" onClick={onClick} >{t("profile")}</a>
                                <a class="list-group-item list-group-item-action" id="editprofile" onClick={onClick} >{t("editProfile")}</a>
                                <a class="list-group-item list-group-item-action" id="ticket" onClick={onClick}>{t("ticketssettings")}</a>
                                <a class="list-group-item list-group-item-action" id="review" onClick={onClick}>{t("reviewsettings")}</a>
                                <a class="list-group-item list-group-item-action" id="account" onClick={onClick}>{t("packages&prices")}   </a>
                                <a class="list-group-item list-group-item-action" id="bank" onClick={onClick}>{t("financialoperations")}</a>
                                <button style={{ marginTop: "5%", marginBottom: "3%" }} className="btn btn-secondary" onClick={handleLogout}> {t("singout")}</button>

                            </div>
                        </div>

                        <div className="col-sm-9" style={{ backgroundColor: "white", marginTop: "3%" }}>
                            <br />
                            <h3>{msg}</h3>
                            <h4> {selectpackagemessage} </h4>
                            <br />
                            {/* <div id="profiledetail" style={{ display: "none" }}>
                        <GeneralProfile />
                    </div> */}
                            <div id="profiledetail" style={{ display: "none" }}>
                                <GeneralProfile />
                            </div>
                            {
                                <div id="editprofilee" style={{ display: "none" }}>
                                    <ChangePassword />
                                </div>
                            }

                            <div id="accountdetail" style={{ display: "none" }}>
                                <PackagePrice />
                            </div>


                            <div id="bankdetail" style={{ display: "none" }}>
                                <PaymentMethodList />
                                <PaymentRequest />
                            </div>

                            <div id="ticketdetail" style={{ display: "none" }}>
                                <div style={{ textAlign: "center" }}>
                                    <h3>{t("ticketssettings")}</h3>
                                </div>
                                {
                                    (() => {
                                        if (user.user.roleName === "Manager") {
                                            return (
                                                <ManagerTicket />
                                            );
                                        }
                                        else if (user.user.roleName === "Student") {
                                            return (
                                                <StudentTicket />
                                            );

                                        }
                                    })()
                                }

                                <br />
                            </div>

                            <div id="reviewdetail" style={{ display: "none" }}>
                                <Review />
                            </div>

                        </div>
                    </div>
                </div >
            </BrowserView>
            <MobileView>
                <div>
                    <div style={{ marginTop: "3%" }}>
                        <div class="list-group list-group-flush" >
                            <button style={{ marginBottom: "5%" }} className="btn btn-info" onClick={handleBack}>Panele Dön</button>
                            <a class="list-group-item list-group-item-action" id="profile" onClick={onClick} >{t("profile")}</a>
                            <a class="list-group-item list-group-item-action" id="editprofile" onClick={onClick} >{t("editProfile")}</a>
                            <a class="list-group-item list-group-item-action" id="ticket" onClick={onClick}>{t("ticketssettings")}</a>
                            <a class="list-group-item list-group-item-action" id="review" onClick={onClick}>{t("reviewsettings")}</a>
                            <a class="list-group-item list-group-item-action" id="account" onClick={onClick}>{t("packages&prices")}   </a>
                            <a class="list-group-item list-group-item-action" id="bank" onClick={onClick}>{t("financialoperations")}</a>
                            <button style={{ marginTop: "5%", marginBottom: "3%" }} className="btn btn-secondary" onClick={handleLogout}> {t("singout")}</button>

                        </div>
                    </div>

                    <div style={{ backgroundColor: "white", marginTop: "3%" }}>
                        <br />
                        <h3>{msg}</h3>
                        <h4> {selectpackagemessage} </h4>
                        <br />
                        {/* <div id="profiledetail" style={{ display: "none" }}>
                        <GeneralProfile />
                    </div> */}
                        <div id="profiledetail" style={{ display: "none" }}>
                            <GeneralProfile />
                        </div>
                        {
                            <div id="editprofilee" style={{ display: "none" }}>
                                <ChangePassword />
                            </div>
                        }

                        <div id="accountdetail" style={{ display: "none" }}>
                            <PackagePrice />
                        </div>


                        <div id="bankdetail" style={{ display: "none" }}>
                            <PaymentMethodList />
                            <PaymentRequest />
                        </div>

                        <div id="ticketdetail" style={{ display: "none" }}>
                            <div style={{ textAlign: "center" }}>
                                <h3>{t("ticketssettings")}</h3>
                            </div>
                            {
                                (() => {
                                    if (user.user.roleName === "Manager") {
                                        return (
                                            <ManagerTicket />
                                        );
                                    }
                                    else if (user.user.roleName === "Student") {
                                        return (
                                            <StudentTicket />
                                        );

                                    }
                                })()
                            }

                            <br />
                        </div>

                        <div id="reviewdetail" style={{ display: "none" }}>
                            <Review />
                        </div>

                    </div>
                </div>
            </MobileView>
        </div>
    )

}
export default Profile;
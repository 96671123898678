import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../context/AuthContext';
import axios from "axios";
const PackagePrice = (props) => {
    const { t } = useTranslation()
    const { user } = useAuth();
    const [loadingg, setLoadingg] = useState(true);
    const [packages, setPackages] = useState([]);
    const [msg, setMsg] = useState([]);

    const fetchDataPackages = async () => {
        setLoadingg(true);
        try {
            const headerss = {
                "accept": "*/*",
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'false',
                'Authorization': 'Bearer ' + user.token
            }
            let url = "https://api.heryerdeingilizce.com/api/User/GetPackages";
            await axios({
                method: 'GET',
                url: url,
                headers: headerss
            }).then(res => {
                if (res.status === 200) {
                    if (res.data['success']) {
                        for (let i = 0; i < res.data['data'].length; i++) {
                            let a = user.role;
                            if (a === "Manager") {
                                if (res.data['data'][i]['isActive']) {
                                    if (!res.data['data'][i]['isInvidual']) {
                                        packages.push({
                                            "id": res.data['data'][i]['id'],
                                            "packageName": res.data['data'][i]['packageName'],
                                            "packageDetail": res.data['data'][i]['packageDetail'],
                                            "packagePrice": res.data['data'][i]['packagePrice'],
                                            "howManyDays": res.data['data'][i]['howManyDays'],
                                        })
                                    }
                                }
                            }
                            else if (a === "Student") {
                                if (res.data['data'][i]['isActive']) {
                                    if (res.data['data'][i]['isInvidual']) {
                                        packages.push({
                                            "id": res.data['data'][i]['id'],
                                            "packageName": res.data['data'][i]['packageName'],
                                            "packageDetail": res.data['data'][i]['packageDetail'],
                                            "packagePrice": res.data['data'][i]['packagePrice'],
                                            "howManyDays": res.data['data'][i]['howManyDays'],
                                        })
                                    }
                                }
                            }

                        }
                    }
                    else {
                        setMsg(res.data['message']);
                    }
                }
                else {
                    setMsg("Beklenmedik Bir Hata Oluştu. Tekrar Deneyiniz.");
                }
            });
        }
        catch (err) {
            setMsg(err);
        }
        setLoadingg(false);
    }
    useEffect(() => {
        fetchDataPackages();
    }, []);

    return (
        <div>
            <div style={{ textAlign: "center" }}>
                <h3>{t('packages&prices')}</h3>
            </div>
            <br />
            <hr />
            <div style={{ textAlign: "center" }}>
                <h4>{t("packagesList")}</h4>
            </div>
            {loadingg && <div>Veriler Yüklenirken Bir Hata Meydana Geldi!</div>}
            {!loadingg && (
                <div style={{ overflowX: "auto", border: "1px solid black" }}>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">{t("id")}</th>

                                <th scope="col">{t("packageName")}</th>
                                <th scope="col">{t("packageDescription")}</th>
                                <th scope="col">{t("packagePrice")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {packages && packages.map((dt) => {
                                return (
                                    <tr>
                                        <th scope="row">{dt.id}</th>
                                        <td>{dt.packageName}</td>
                                        <td>{dt.packageDetail}</td>
                                        <td>{dt.packagePrice}</td>

                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}


            <hr />
            <br />  <br />
        </div>
    );
};

export default PackagePrice;
import React from 'react';
import { useTranslation } from "react-i18next";
import { useAuth } from '../../../context/AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TeacherSidebarDetail = (props) => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth();
    return (
        <ul class="list-unstyled components">
            <li id="assignment">
                <div>
                    <a href="#" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#assignmentarea"
                        aria-controls="assignmentarea"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-paperclip" />
                        </b>

                        <b className="forsidebar">&nbsp;Assignment</b>

                    </a>
                    <ul class="collapse list-unstyled" id="assignmentarea">
                        <li>
                            <a href="#">

                                <b className="forsidebar">
                                    Skills
                                </b>
                            </a>
                        </li>

                        <li>
                            <a href="#">
                                <b className="forsidebar">
                                    General Progress
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <b className="forsidebar">
                                    General Report
                                </b>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <br />
            <li id="coursesteacher">
                <div>
                    <li>
                        <a href="/dashboard/teacher/studentinfo">
                            <b>
                                <FontAwesomeIcon icon="fas fa-university" />
                            </b>

                            <b className="forsidebar">&nbsp;Student Info</b>
                        </a>
                    </li>
                </div>

            </li>
            <br />
            <li id="classesteacher">
                <div>
                    <a href="#" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#class"
                        aria-controls="class"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-users" />
                        </b>
                        <b className="forsidebar">
                            &nbsp;

                            {t("classes")}

                        </b>
                    </a>
                    <ul class="collapse list-unstyled" id="class">
                        <li>
                            <a href="/dashboard/teacher/classlist">
                                <b><FontAwesomeIcon icon="fas fa-user-friends" /></b>
                                <b className="forsidebar">
                                    {t("classlist")}
                                </b>
                            </a>
                        </li>

                        <li>
                            <a href="/dashboard/teacher/classcreate">
                                <b>
                                    <FontAwesomeIcon icon="fas fa-user" />
                                </b>
                                <b className="forsidebar">
                                    {t("createclass")}
                                </b>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <br />
            <li id="studentsteacher">
                <div>
                    <a href="#" data-toggle="collapse" className="dropdown-toggle" data-bs-toggle="collapse"
                        data-bs-target="#student"
                        aria-controls="student"
                    >
                        <b>
                            <FontAwesomeIcon icon="fas fa-users" />
                        </b>
                        <b className="forsidebar">
                            &nbsp;
                            {t("students")}
                        </b>
                    </a>
                    <ul class="collapse list-unstyled" id="student">
                        <li>
                            <a href="/dashboard/teacher/studentcreate">
                                <b className="forsidebar">
                                    {t("createstudent")}
                                </b>
                            </a>
                        </li>
                        <li>
                            <a href="/dashboard/teacher/studentlist">
                                <b className="forsidebar">
                                    {t("studentlist")}
                                </b>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>

        </ul>
    );
};

export default TeacherSidebarDetail;